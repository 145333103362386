<template>
  <div class="bg-white shadow-md rounded-lg py-12 px-8 mx-auto mt-6 max-w-[976px]">
    <h2 class="text-xl font-bold mb-4">livedoor ECHOES ご利用ガイドライン</h2>
    <!-- ガイドライン本文 -->
    <UserGuidelineBody />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import UserGuidelineBody from './UserGuidelineBody.vue';

export default defineComponent({
    components: {
        UserGuidelineBody
    }
})
</script>

<style scoped>
/* ガイドライン画面外枠 */
.text-xl {
  font-family: HelveticaNeue;
}
</style>