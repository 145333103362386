// グローバル定数を設定
export const BASE_URL_DEV = 'https://echoes.livedoormlsystem.com';
export const BASE_URL_LOCAL = 'http://localhost:8080';
export const BREAK_POINT_MD = 768;

// 公開ステータス
export const VIDEO_STATUS_PRE_PUBLICATION = '下書き';
export const VIDEO_STATUS_SCHEDULED = '予約投稿';
export const VIDEO_STATUS_PUBLISHED = '公開';
export const VIDEO_STATUS_DELETING = '削除待ち';
export const VIDEO_STATUS_SYSTEM_PROCESSING = 'システム処理中';
export const VIDEO_STATUS_PUBLISHING_QUEUED = '公開待ち';

