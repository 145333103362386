<template>
  <Information/>
</template>

<script>
  import Information from './InformationTable.vue';
  export default {
    name: 'DashboardPage',
    components: {
        Information,
    },
  };
</script>
