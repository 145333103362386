<template>
  <div class="bg-white shadow-md rounded-lg p-6 mx-auto mt-6 max-w-7xl">

    <!-- エラーメッセージ -->
    <div v-if="errorMessage" class="alert-error" role="alert">
      <img :src="alertIcon" alt="alert icon" class=" w-4 h-4 mt-1">
      {{ errorMessage }}
      <button type="button" class="alert-close" @click="clearErrorMessage">
          <svg class="w-6 h-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <title>Close</title>
            <path
            d="M14.348 14.849a1 1 0 0 1-1.414 0L10 11.414 7.056 14.349a1 1 0 1 1-1.414-1.414L8.586 10 5.642 7.056a1 1 0 0 1 1.414-1.414L10 8.586l2.944-2.944a1 1 0 0 1 1.414 1.414L11.414 10l2.934 2.935a1 1 0 0 1 0 1.414z" />
          </svg>
      </button>
    </div>
    <h1 class="text-xl font-bold mb-4 mt-4">記事公開設定</h1>
    <div class="grid lg:grid-cols-3 gap-4">

      <div class="lg:col-span-2">
        <div>
          <label class="block mb-2 font-semibold text-base">公開日時 <span class="text-red-500 text-sm">必須</span></label>
          <div class="mb-4">
              <input type="radio" id="publishDraft" value="draft" v-model="publishOption" class="mr-2">
              <label for="publishDraft">下書き保存する</label>
          </div>
          <div class="mb-4">
            <input type="radio" id="publishNow" value="now" v-model="publishOption" class="mr-2">
            <label for="publishNow">すぐに公開する</label>
          </div>
          <div v-if="videoStatusDisplay !== VIDEO_STATUS_PUBLISHED">
              <div class="mb-4">
                <input type="radio" id="publishSchedule" value="schedule" v-model="publishOption" class="mr-2">
                <label for="publishSchedule">予約投稿する</label>
                <!--<input type="datetime-local" v-if="publishOption === 'schedule'" v-model="scheduledTime" class="ml-4 p-2 border rounded">-->
                <!-- 予約投稿の日時入力フィールド -->
                <div v-if="publishOption === 'schedule'" class="mb-4">
                  <input type="datetime-local" v-model="scheduledTime" class="ml-4 p-2 border rounded">
                  <div v-if="isScheduledTimeInvalid" class="text-red-500">過去の日付、時間は設定できません。</div>
                </div>
            </div>
            <div v-if="!publishOption && validationAttempted" class="text-red-500">必須項目です。選択してください。</div>
          </div>
        </div>

        <div class="mb-4">

          <div class="flex items-center mb-2">
            <label class="block mr-2 font-semibold text-base">記事タイトル <span
                class="text-red-500 text-sm">必須</span></label>
            <!-- ツールチップトリガー -->
            <div class="relative group" @mouseover="showTooltip = true" @mouseleave="showTooltip = false">
              <!-- SVG アイコン -->
              <img :src="tooltipIcn" alt="info" class="w-4 h-4">
              <!-- ツールチップコンテンツ -->
              <div v-if="showTooltip"
                class="absolute hidden group-hover:block bg-gray-700 text-white text-sm rounded-lg p-2 -mt-1 ml-5"
                style="width: 200px; z-index: 50;">
                絵文字、旧漢字、HTMLタグは使用できません。
              </div>
            </div>
          </div>
          <div v-for="(title, key) in articleTitles" :key="key" class="mb-2">
            <input type="radio" :id="key" :value="title" v-model="selectedTitle" class="mr-2">
            <label :for="key">{{ title }}</label>
          </div>
          <div>
            <input type="radio" id="customTitle" value="custom" v-model="selectedTitle" class="mr-2">

            <label for="customTitle">自分で作成する</label>

            <!--<input type="text" v-if="selectedTitle === 'custom'" v-model="customTitle" :readonly="selectedTitle !== 'custom'" placeholder="タイトルを記述してください。" class="w-full p-2 border rounded">-->
            <input type="text" v-model="customTitle" :readonly="selectedTitle !== 'custom'" placeholder="タイトルを記述してください。"
              class="w-full p-2 border rounded">

          </div>
          <div
            v-if="!selectedTitle && validationAttempted || (selectedTitle === 'custom' && !customTitle && validationAttempted)"
            class="text-red-500">記事タイトルは必須です。また旧漢字やHTMLタグ、絵文字は使用できません。</div>
        </div>

        <div class="mb-4">
          <label class="block mb-2 font-semibold text-base">記事カテゴリ <span class="text-red-500 text-sm">必須</span></label>
          <select v-model="selectedCategory" class="p-2 border rounded">
            <option disabled value="">選択してください</option>
            <option v-for="category in categories" :key="category" :value="category">{{ category }}</option>
          </select>
          <div v-if="!selectedCategory && validationAttempted" class="text-red-500">必須項目です。選択してください。</div>
        </div>

        <div class="mb-4">
          <!-- 記事本文とツールチップアイコンを横に並べる -->
          <div class="flex items-center mb-2">
            <label class="block mr-2 font-semibold text-base">記事本文 <span class="text-red-500 text-sm">必須</span></label>
            <!-- ツールチップトリガー -->
            <div class="relative group" @mouseover="showTooltip = true" @mouseleave="showTooltip = false">
              <!-- SVG アイコン -->
              <img :src="tooltipIcn" alt="info" class="w-4 h-4">
              <!-- ツールチップコンテンツ -->
              <div v-if="showTooltip"
                class="absolute hidden group-hover:block bg-gray-700 text-white text-sm rounded-lg p-2 -mt-1 ml-5"
                style="width: 200px; z-index: 50;">
                絵文字、旧漢字、HTMLタグは使用できません。
              </div>
            </div>
          </div>
          <!-- テキストエリア -->
          <textarea v-model="articleBody" class="w-full h-52 p-2 border rounded"></textarea>
          <div v-if="!publishOption && validationAttempted" class="text-red-500">記事本文は必須です。また旧漢字やHTMLタグ、絵文字は使用できません。
          </div>
        </div>

        <div class="mb-4">

          <div class="flex justify-between items-center mb-4">
            <div class="flex items-center mb-2">
              <label class="block font-semibold text-base">YouTubeの動画内容</label>
              <!-- ツールチップトリガー -->
              <div class="relative group" @mouseover="showTooltip = true" @mouseleave="showTooltip = false">
                <!-- SVG アイコン -->
                <img :src="tooltipIcn" alt="info" class="w-4 h-4">
                <!-- ツールチップコンテンツ -->
                <div v-if="showTooltip"
                  class="absolute hidden group-hover:block bg-gray-700 text-white text-sm rounded-lg p-2 -mt-1 ml-5"
                  style="width: 200px; z-index: 50;">
                  絵文字、旧漢字、HTMLタグは使用できません。
                </div>
              </div>
            </div>
            <button @click="openModal" class="text-blue-500">YouTubeの動画内容とは</button>
            <!-- YouTubeの動画内容説明モーダル -->
            <div v-if="isInfoModalOpen"
              class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center z-50"
              @click="closeModal">
              <div class="bg-white p-5 rounded-lg max-w-md mx-auto" @click.stop>
                <h3 class="text-xl font-bold mb-4">YouTubeの動画内容とは？</h3>
                <p class="mb-2">記事本文の下に表示されるYouTubeの目次（チャプター）リンクです。読者が見たい情報が目に留まりやすくなるので、YouTubeへ遷移してもらいやすくなります。</p>
                <p class="mb-4">※表示サンプル</p>

                <!-- 実際のvideoSegmentsから説明要素を動的に表示 -->
                <div class="border border-gray-200 rounded-lg">
                  <div v-for="(segment, index) in videoSegments" :key="index" class="flex items-center mt-2 mb-2 pl-2">
                    <span class="text-timestampBlue bg-timestampLightBlue p-1 rounded mr-2">{{ segment.timestamp
                      }}</span>
                    <span class="text-gray-700">{{ segment.title }}</span>
                  </div>
                </div>

                <button @click="closeModal" class="mt-4 bg-gray-500 text-white p-2 rounded">閉じる</button>
              </div>
            </div>
          </div>

          <!--timestamp部分-->
          <div class="mb-2">

            <draggable v-model="videoSegments" ghost-class="ghost" handle=".handle" chosen-class="chosen"
              animation="200">
              <template #item="{ element, index }">
                <div class="mb-2 relative flex items-center bg-gray-100 p-3 rounded">
                  <button class="handle mr-2 flex items-center text-gray-500">
                    <v-icon>mdi-drag-vertical</v-icon>
                  </button>
                  <span class="cursor-pointer text-blue-500 bg-blue-100 p-1 rounded mr-2 relative"
                    @click="setVideoStart(element.timestamp)" @mouseover="showEditBlock(index)"
                    @mouseleave="hideEditBlock(index)">
                    {{ element.timestamp }}
                    <!-- 編集ブロック -->
                    <div v-if="element.editing"
                      class="absolute top-full left-0 mt-2 p-2 border rounded bg-white z-10 w-72"
                      :class="{ 'z-20': element.isActive, 'z-10': !element.isActive }"
                      @mouseenter="keepEditBlock(index)" @mouseleave="hideEditBlock(index)"
                      @click.stop="bringToFront(index)" :ref="'editBlock' + index">
                      <div class="flex flex-col items-start mb-2 text-black">
                        <div class="flex justify-between items-center w-full mb-2">
                          <span class="p-1 mb-2">
                            時間を編集
                          </span>
                          <button @click="closeTimestampEdit(index)" class="text-black-500 mb-2">
                            <v-icon>mdi-close</v-icon>
                          </button>
                        </div>
                        <div class="flex items-center w-full">
                          <input type="text" v-model="element.newTimestamp" class="p-2 border rounded w-full mb-2"
                            @input="validateTimestampOnInput(index)">
                          <button @click="toggleTimePicker(index)" class="ml-2 flex items-center mb-2">
                            <v-icon>mdi-clock-outline</v-icon>
                          </button>
                        </div>
                        <button @click="updateTimestamp(index)" :disabled="!element.isValid"
                          class="p-2 rounded w-full bg-emerald-600 hover:bg-emerald-700 text-white"
                          :class="{ 'opacity-50': !element.isValid }">
                          保存
                        </button>
                        <span v-if="!element.isValid"
                          class="absolute bottom-full left-1/2 transform -translate-x-1/2  bg-black text-white text-xs rounded py-1 px-2 w-72 text-center">
                          半角数字と:のみ入力可能です。<br>
                          形式は00:00:00に合わせてください。
                        </span>
                        <v-time-picker v-if="element.showTimePicker" v-model="element.newTimestamp" format="24hr"
                          @change="setSelectedTime(index, $event)" @click:close="hideTimePicker(index)" use-seconds
                          :style="{ width: '256px', padding: '10px 0px 0px 0px', margin: '0 auto' }"></v-time-picker>

                      </div>
                    </div>
                  </span>
                  <input type="text" v-model="element.title" class="p-2  rounded flex-grow mr-2 bg-white">
                  <button @click="deleteTimestamp(index)" class="ml-2 flex items-center text-red-500">
                    <v-icon>mdi-close</v-icon>
                  </button>
                </div>
              </template>
            </draggable>

            <!-- 追加ブロック -->
            <div class="flex items-center mb-7 mt-4 relative">
              <span class="cursor-pointer text-blue-500 bg-blue-100 p-1 rounded mr-2" @click="showNewTimestampEdit">
                {{ displayTimestamp }}
              </span>
              <input type="text" v-model="newTitle" class="p-2 border rounded mr-2 flex-grow"
                placeholder="タイトルを記述してください。" @input="validateNewTitle">
              <button @click="addTimestamp" :disabled="!isAddValid" :class="[
                isAddValid ? 'btn-primary' : 'disabled:btn-disabled'
              ]">
                追加
              </button>
              <!-- 新しいタイムスタンプの編集ブロック -->
              <div v-if="newTimestampEditing"
                class="absolute top-full left-0 mt-2 p-2 border rounded bg-white z-10 w-72" @click.stop>
                <div class="flex flex-col items-start mb-2">
                  <div class="flex justify-between items-center w-full mb-2">
                    <span class="p-1 mb-2">
                      時間を編集
                    </span>
                    <button @click="closeNewTimestampEdit" class="text-black-500 mb-2">
                      <v-icon>mdi-close</v-icon>
                    </button>
                  </div>
                  <div class="flex items-center w-full">
                    <input type="text" v-model="newTimestamp" @input="validateNewTimestampOnInput"
                      class="p-2 border rounded w-full mb-2">
                    <button @click="toggleNewTimePicker" class="ml-2 flex items-center mb-2">
                      <v-icon>mdi-clock-outline</v-icon>
                    </button>
                  </div>
                  <button @click="confirmNewTimestamp"
                    class="btn-primary"
                    :class="{ 'disabled:btn-disabled': !isNewTimestampValid }" :disabled="!isNewTimestampValid">
                    保存
                  </button>
                  <span v-if="!isNewTimestampValid"
                    class="absolute bottom-full left-1/2 transform -translate-x-1/2 bg-black text-white text-xs rounded py-1 px-2 w-72 text-center">
                    半角数字と:のみ入力可能です。<br>
                    形式は00:00:00に合わせてください。
                  </span>
                  <v-time-picker v-if="newTimePickerVisible" v-model="newTimestamp" format="24hr" full-width
                    @change="handleTimePickerChange" @click:close="hideNewTimePicker" use-seconds
                    :style="{ width: '256px', padding: '10px 0px 0px 0px', margin: '0 auto', backgroundColor: 'white' }"></v-time-picker>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 関連動画 -->
        <ArticleRelatedVideos :related-video-urls="relatedVideoUrls" @parentFunc1="updateInputUrls"  @parentFunc2="updateInputUrlsValidStatus" />

      </div>

      <div class="lg:col-span-1">
        <iframe :src="youtubeEmbedUrl" title="YouTube video player" class="w-full aspect-video" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen>
        </iframe>

        <div class="bg-gray-100 p-4 rounded text-sm">
          <p>YouTube動画URL<br><a :href="youtubeEmbedUrl" class="text-blue-500">{{ youtubeEmbedUrl }}</a></p>
          <p>タイトル<br> {{ videotitle }}</p>
        </div>
      </div>

    </div>
    <!--操作ボタン-->

    <div class="flex flex-col sm:flex-row justify-between mt-8 w-full">
      <!-- キャンセルボタン -->
      <button @click="cancel"
        class="btn-secondary sm:mb-0 sm:mr-2 max-h-fit">キャンセル</button>

      <!-- 右側のボタン群 -->
      <div class="flex justify-end sm:flex-row">
        <div class="flex">
          <button @click="preview()" :disabled="canSubmit !== 'OK' || !isRelatedVideoURLValid || isSubmitting"
            :class="{
              'btn-secondary': canSubmit == 'OK' && isRelatedVideoURLValid,
              'btn-disabled': canSubmit !== 'OK' || !isRelatedVideoURLValid }"
            class="sm:mb-0 sm:mr-2 flex-grow sm:flex-grow-0 max-h-fit"
            @mouseover="updateTooltip"
            v-tooltips.top="{
                value: tooltipValue,
                pt: {
                    arrow: {
                        style: {
                            borderButtomColor: 'bg-gray-500',
                        }
                    },
                    text: '!bg-gray-700 !text-white !rounded-lg !p-2 !mb-1 !text-center !text-xs'
                }}">
            プレビューを見る
          </button>
        </div>
        <div class="flex">
          <button @click="submit" :disabled="canSubmit !== 'OK' || !isRelatedVideoURLValid || isSubmitting"
            :class="{
              'btn-primary': canSubmit == 'OK' && isRelatedVideoURLValid,
              'btn-disabled': canSubmit !== 'OK' || !isRelatedVideoURLValid}"
            class="w-full sm:w-auto max-h-fit"
            @mouseover="updateTooltip"
            v-tooltips.top="{
                value: tooltipValue,
                pt: {
                    arrow: {
                        style: {
                            borderButtomColor: 'bg-gray-500',
                        }
                    },
                    text: '!bg-gray-700 !text-white !rounded-lg !p-2 !mb-1 !text-center !text-xs'
                }}">
            {{submitBtnText}}
          </button>
        </div>
      </div>
    </div>
    <!-- プレビューモーダル -->
    <div v-if="isPreviewModalOpen"
      class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center p-4"
      @click="closePreviewModal">
      <div class="bg-white p-5 rounded-lg max-w-lg max-h-[80vh] overflow-auto" @click.stop>
        <img :src="previewImage" alt="プレビュー画像" class="max-h-[calc(100vh-5rem)] max-w-full object-contain">
        <button @click="isPreviewModalOpen = false" class="mt-4 bg-gray-500 text-white p-2 rounded">閉じる</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import previewImage from '@/assets/previewImage.png';
import alertIcon from '@/assets/alert.svg';
import tooltipIcn from '@/assets/help.svg';
import draggable from 'vuedraggable';
import ArticleRelatedVideos from './ArticleRelatedVideos.vue';
import { ref, reactive } from 'vue';
import { 
    VIDEO_STATUS_PRE_PUBLICATION,
    VIDEO_STATUS_PUBLISHED
} from '@/plugins/constants';

export default {
  directives: {
    tooltip: {
      mounted(el, binding) {
        el.title = binding.value;
        el.classList.add('tooltip');
      }
    }
  },
  components: {
    draggable,
    ArticleRelatedVideos,
  },
  data() {
    return {
      articleId: null,
      publishOption: 'draft',
      scheduledTime: '',
      youtubeEmbedUrl: '',
      youtubePreviewUrl: '', // プレビュー画面で使用
      videotitle: '',
      articleTitles: {},
      selectedTitle: '',
      customTitle: '',
      categories: ["政治", "国内", "経済", "IT", "海外", "エンタメ", "ゲーム", "アニメ", "マンガ＆ノベル", "サッカー", "野球", "格闘技", "競馬", "スポーツその他", "ファッション・ビューティー", "ライフスタイル", "グルメ", "恋愛", "健康", "仕事・教育"],
      selectedCategory: '',
      articleBody: '',
      articleUrl: '', // 記事公開済みかどうか判定で使用
      videoSegments: [],
      validationAttempted: false, // バリデーションチェックが行われたかどうかを追跡するフラグ
      isInfoModalOpen: false,
      isPreviewModalOpen: false, // プレビューモーダルの表示状態
      previewImage, // プレビュー画像
      youtubeVideoId: '', // YouTube動画のID
      validationErrors: {}, // バリデーションエラーを保持するオブジェクト
      errorMessage: '', // エラーメッセージを保持するプロパティ
      tooltipIcn,
      alertIcon,
      showTooltip: false,
      isMouseOverEditBlock: false,
      currentEditingIndex: null,
      newTimestamp: '00:00:00', // 実際の値として使用
      displayTimestamp: '--:--:--', // 表示用のプロパティ
      newTitle: '',
      activeEditIndex: null,
      isAddValid: false,
      newTimestampEditing: false,
      newTimePickerVisible: false,
      isNewTimestampValid: false,
      isNewTitleValid: false,
      //関連動画関連
      isRelatedVideoURLValid: true,
      relatedVideoUrls: [],
      relatedVideoFeeds: [],
      originalChannel: ref({}),
      videos: ref([]),
      thumbnail: '',
      inputUrls: [],
      savedTop3VideoData: null,
      top3VideoDataJson: {},
      //確定ボタン関連
      tooltipValue: '',
      // 記事ステータス
      videoStatusDisplay: '',
      submitBtnText: '',
      isSubmitting: false,
      canArticleUpdate: true,
      VIDEO_STATUS_PUBLISHED,
    };
  },
  watch: {
    publishOption(newVal) {
      if(newVal == 'draft'){
        this.submitBtnText = '下書き保存する'
      }else{
        this.submitBtnText = '公開内容を確定する'
      }
    }
  },
  async mounted() {
    this.articleId = this.$route.params.id;
    //記事データの取得
    this.fetchArticleSettings();
    document.addEventListener('click', this.handleClickOutside);
    try{
      // ローカルストレージからデータを取得
      const user_id = this.$store.state.user_id;
      // localStorage.removeItem('top3VideoData-' + user_id);
      this.savedTop3VideoData = localStorage.getItem('top3VideoData-' + user_id);
      //再生回数TOP3動画の取得
      if (this.savedTop3VideoData !== null){
        this.top3VideoDataJson = JSON.parse(this.savedTop3VideoData);
        // console.log("top3VideoData in LocalStorage.",this.top3VideoDataJson);
        const expirationDate = this.top3VideoDataJson['expirationDate'];
        const expiration = new Date(expirationDate);
        const today = new Date();
        if ( today <= expiration) {
          // console.log('再生回数TOP3の動画を取得したことがあり、有効期限内');
          await this.fetchRelatedVideoInfo("pageOpen", "Saved");
        }else{
          // console.log('再生回数TOP3の動画を取得したことがあるが、有効期限切れ');
          await this.fetchRelatedVideoInfo("pageOpen", "noSave");
        }
      }else{
        // console.log("再生回数TOP3の動画を取得したことがない")
        await this.fetchRelatedVideoInfo("pageOpen", "noSave");
      }
    }catch (error) {
      console.error(error);
      return;
    }
    try{
      this.fetchChannelData();
    }catch (error) {
      console.error('チャンネル情報の取得に失敗しました。:', error);
    }
    try{
      this.fetchVideoData();
    }catch (error) {
      console.error('投稿情報の取得に失敗しました。:', error);
    }
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  computed: {
    isScheduledTimeInvalid() {
      if (!this.scheduledTime) return false;
      const now = new Date();
      const scheduledDate = new Date(this.scheduledTime);
      return scheduledDate < now;
    },
    canSubmit() {
      //初期化
      var validStatus = "OK";
      var isTitleValid = true;
      var isCategoryValid = true;
      var isBodyValid = true;
      var isScheduledTimeValid = true;
      const htmlTagPattern = /<[^>]+>/; // HTMLタグの存在チェック（script, img および 一般的なタグ）
      const oldKanjiPattern = /[亞惡壓圍爲醫稻隱衞驛鹽橫毆價畫勸觀閒邊邉龜豐鬥爭髙﨑圀龜豐鬥爭髙﨑圀]/; // 旧漢字のチェック（例: "﨑" のような文字が含まれているか）
      const pictogramPattern = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/u; // 絵文字のチェック

      // 必須項目が未入力か（下書き時はチェックしない）
      if(this.publishOption != 'draft'){
        isTitleValid = !!this.selectedTitle && (this.selectedTitle !== 'custom' || this.customTitle) //タイトルが未入力
        isCategoryValid = !!this.selectedCategory; //カテゴリが未入力
        isBodyValid = !!this.articleBody; //記事本文が未入力
        isScheduledTimeValid = this.publishOption !== 'schedule' || (this.publishOption === 'schedule' && this.scheduledTime && !this.isScheduledTimeInvalid);
        const isTCBSValid = isTitleValid && isCategoryValid && isBodyValid && isScheduledTimeValid;
        if(!isTCBSValid){
          return "NG-01";
        }
      }

      // 使用できない文字が含まれているか
      if(this.publishOption == 'draft' && this.selectedTitle == 'custom' && this.customTitle == ""){
        isTitleValid = true;
      }else{
        isTitleValid = (this.selectedTitle !== 'custom' || (this.customTitle && !pictogramPattern.test(this.customTitle))) && !htmlTagPattern.test(this.customTitle) && !oldKanjiPattern.test(this.customTitle);
      }
      isBodyValid = !pictogramPattern.test(this.articleBody) && !htmlTagPattern.test(this.articleBody) && !oldKanjiPattern.test(this.articleBody);
      var isSegmentTitlesValid = this.videoSegments?.length === 1 ?
        (!htmlTagPattern.test(this.videoSegments[0].title) &&
          !oldKanjiPattern.test(this.videoSegments[0].title) &&
          !pictogramPattern.test(this.videoSegments[0].title))
        :
        this.videoSegments?.every(segment => {
          return !htmlTagPattern.test(segment.title) &&
            !oldKanjiPattern.test(segment.title) &&
            !pictogramPattern.test(segment.title);
        });
      const isTCBValid = isTitleValid && isBodyValid && isSegmentTitlesValid;
      if(!isTCBValid){
        return "NG-02";
      }
      return validStatus
    },
  },
  methods: {
    updateTooltip() {
      this.tooltipValue = this.canSubmit !== 'OK' || this.isRelatedVideoURLValid == false ? this.getTooltipValue(this.canSubmit,this.isRelatedVideoURLValid) : '';
    },
    getTooltipValue(canSubmit,isRVURLValid) {
      if (canSubmit == "NG-01") {
        return '必須項目を入力してください';
      }else if (canSubmit == "NG-02") {
        return '入力された内容に使用できない\r\n文字が含まれています';
      }else if (!isRVURLValid) {
        return '関連動画の取得に失敗しました\r\nURLを確認ください';
      }else{
        return '';
      }
    },
    updateInputUrls(userInputUrls){
      // 値のある要素を配列の前に詰めるよう並び替え
      this.inputUrls = userInputUrls.filter(url => url).concat(userInputUrls.filter(url => !url));
    },
    updateInputUrlsValidStatus(isDisabled) {
      this.isRelatedVideoURLValid = isDisabled;
    },
    async fetchRelatedVideoInfo(action, isSaved){
      return new Promise((resolve,reject) => {
        const accessToken = this.$store.state.accessToken;
        const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;
        let postData = {
          action: action,
          article_id: this.articleId,
          video_urls: this.inputUrls,
          is_saved: isSaved,
        };
        axios.post(`${apiEndpoint}/related-videos`, postData,{
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        }).then(response => {
          const message = response.data.message;
          if(message == 'useLocalStorage'){
            this.relatedVideoUrls = this.top3VideoDataJson['top3VideoUrls'];
          }else if(message == 'videoSearched'){
            this.relatedVideoUrls = response.data.videoUrls;
            const user_id = this.$store.state.user_id;
            const top3VideoDataJson = {
                                        'top3VideoUrls': response.data.videoUrls,
                                        'expirationDate': response.data.expirationDate
                                      }
            localStorage.setItem('top3VideoData-' + user_id, JSON.stringify(top3VideoDataJson));
          }else if(message == 'feedSearched'){
            if (response.data.videoFeeds.length > 0){
              this.relatedVideoFeeds = response.data.videoFeeds;
            }else{
              this.relatedVideoFeeds = [];
            }
          }else{
            // message == 'noSearch'
            // fetchArticleSettings()で過去に確定した関連動画をセットするため何もしない。
          }
          resolve('success');
        }).catch(error => {
          console.error(error);
          this.errorMessage = 'データの取得に失敗しました。画面の再読み込みを行ってください。';
          reject(error);
        });
      });
    },
    checkValueExists(obj, value) {
      return Object.values(obj).includes(value)
    },
    fetchArticleSettings() {
      const accessToken = this.$store.state.accessToken;
      const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;
      axios.get(`${apiEndpoint}/articles/${this.articleId}/settings`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      })
        .then(response => {
          const data = response.data.articleData;
          // console.log("articleData", data);

          if(data.article_related_video_url != null &&
              'urls' in data.article_related_video_url){
            this.relatedVideoUrls = data.article_related_video_url.urls;
          }

          this.articleTitles = data.article_title_candidate;
          
          // データの構造を確認して videoSegments を設定
          if (data.video_chapter && data.video_chapter.segments) {
            this.videoSegments = data.video_chapter.segments.map(segment => ({
              ...segment,
              newTimestamp: segment.timestamp,
              editing: false,
              isValid: this.validateTimestamp(segment.timestamp),
              showTimePicker: false,
              isActive: false,
            }));
          } else if (data.video_chapter && data.video_chapter.segment) {
            this.videoSegments = data.video_chapter.segment.map(segment => ({
              ...segment,
              newTimestamp: segment.timestamp,
              editing: false,
              isValid: this.validateTimestamp(segment.timestamp),
              showTimePicker: false,
              isActive: false,
            }));
          }

          this.videoStatusDisplay = data.video_status_display;
          this.articleBody = data.article_body;
          this.youtubeEmbedUrl = this.convertToEmbedUrl(data.url_youtube);
          this.youtubePreviewUrl = data.url_youtube;
          this.videotitle = data.video_title;
          this.youtubeVideoId = data.video_id_youtube;
          // 以前ユーザーが選択した記事タイトルをセット（例）
          this.selectedTitle = data.selected_title;
          // 以前ユーザーが選択したカテゴリをセット（例）
          this.selectedCategory = data.selected_category;
          // 公開オプション（'now'または'schedule'）をセット（例）
          this.publishOption = data.publish_option;
          // 予約公開日時をセット（例）
          this.scheduledTime = data.scheduled_time ? data.scheduled_time : '';

          const now = new Date();
          const publishedDate = new Date(data.date_published);

          if(data.video_status_display !== VIDEO_STATUS_PRE_PUBLICATION){
            if (publishedDate > now) {
              // 予約投稿の日時が現在より未来の場合
              this.publishOption = 'schedule';

              // publishedDate に 9 時間加算（9 * 60 * 60 * 1000 ミリ秒）
              const jstDate = new Date(publishedDate.getTime() + 9 * 60 * 60 * 1000);
              // JST 日時を ISO 8601 形式の文字列に変換し、日時ローカル入力フィールドの形式に合わせる
              this.scheduledTime = jstDate.toISOString().slice(0, 16);
            } else {
              // それ以外の場合は「今すぐ公開」を選択
              this.publishOption = 'now';
            }
            this.submitBtnText = '公開内容を確定する';
          }else{
            this.publishOption = 'draft';
            this.submitBtnText = '下書き保存する';
          }
          // 記事タイトルがチェックしたものか自分作成か判定
          if(!this.checkValueExists(this.articleTitles, data.selected_title)){
            if(data.selected_title !== '') {
              this.customTitle = data.selected_title;
              this.selectedTitle = 'custom';
            }
          }
          // 記事編集ON/OFF判定
          const articleUpdateFlg = process.env.VUE_APP_NEWS_UPDATE_ONOFF;
          if (articleUpdateFlg == 'OFF') {
              this.canArticleUpdate = false;
              // 記事編集OFFでかつ、公開記事であった場合にTOP画面に遷移
              if (this.videoStatusDisplay == VIDEO_STATUS_PUBLISHED) {
                  this.$router.push({
                  path: '/'
                  });
              }
          }
          this.articleUrl = data.article_url;
        })
        .catch(error => {
          console.error(error);
          this.errorMessage = 'データの取得に失敗しました。画面の再読み込みを行ってください。';
        });
    },
    fetchChannelData() {
      const channel = reactive({
          channel_url: '',
          account_name: '',
          channel_description: '',
          channel_icon: '',
          num_subscribes: '',
          num_uploadvideos: '',
          id: ''
      });
      const fetchChannel = async () => {
          const accessToken = this.$store.state.accessToken;
          const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;

          try {
              const response = await axios.get(`${apiEndpoint}/channels/`, {
                  headers: {
                      'Authorization': `Bearer ${accessToken}`
                  }
              });
              Object.assign(channel, response.data[0]);
              this.originalChannel.value = JSON.parse(JSON.stringify(channel));
          }catch (error) {
              console.error(error);
          }
      };
      fetchChannel();
    },
    fetchVideoData() {
      const fetchVideo = async () => {
          const accessToken = this.$store.state.accessToken;
          const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;

          try {
              const response = await axios.get(`${apiEndpoint}/videos/`, {
                  headers: {
                      'Authorization': `Bearer ${accessToken}`
                  }
              });
              this.videos.value = response.data;
          }catch (error) {
              console.error(error);
          }
      };
      fetchVideo();
    },
    convertToEmbedUrl(url) {
      if (!url) return '';

      const videoIdMatch = url.match(/(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|embed|shorts)\/|\S*?[?&]v=)|youtu.be\/)([a-zA-Z0-9_-]{11})/);

      // 正規表現で動画IDが取得できた場合
      if (videoIdMatch && videoIdMatch[1]) {
        return `https://www.youtube.com/embed/${videoIdMatch[1]}`;
      }

      return '';

    },
    cancel() {
      this.$router.push({
        name: 'ArticleManagement'
      });
    },
    async preview() {
      // ここでプレビュー機能を実装します
      try{
          this.isSubmitting = true;
          await this.fetchRelatedVideoInfo('preview','none');
      }catch (error) {
          console.error(error);
          this.isSubmitting = false;
          return;
      }
      // 初回投稿かどうか判定
      let channelFlg = true;
      for(let i = 0; i < this.videos.value.length > 0; i++) {
        let str = this.videos.value[i].status_transcription_display;
        if(this.videos.value.length == 1 && str == VIDEO_STATUS_PRE_PUBLICATION) {
          channelFlg = false;
        }
      }

      const paramData = {
        "articleId": this.articleId,
        "articleTitle": this.selectedTitle === 'custom' ? this.customTitle : this.selectedTitle,
        "articleBody": this.articleBody,
        "youtubeEmbedUrl": this.youtubeEmbedUrl,
        "youtubePreviewUrl": this.youtubePreviewUrl,
        "youtubeImage": this.thumbnail,
        "videoSegments": this.videoSegments,
        "relatedVideoFeeds": this.relatedVideoFeeds,
        "channel": this.originalChannel,
        "channelFlg": channelFlg
      };
      this.$store.commit('setPreviewData', JSON.stringify(paramData));
      const url = `/articles/settings/` + this.articleId + '/preview';
      const target = 'newWindow' + this.articleId;
      this.isSubmitting = false;
      window.open(url, target);
    },
    async submit() {
      this.isSubmitting = true;
      this.validationAttempted = true; // バリデーションチェックを行ったことを示す
      if (this.canSubmit !== 'OK') {
        // バリデーションエラーがある場合、ここで処理を中断
        this.errorMessage = 'フォームに未入力の必須項目があります。また、記事本文には旧漢字やHTMLタグを含めないでください。';
        this.isSubmitting = false;
        return;
      }
      const accessToken = this.$store.state.accessToken;
      const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;

      // timestampの編集情報から必要なキーだけを取得する処理
      const segmentsToSave = this.videoSegments.map(segment => ({
        title: segment.title,
        timestamp: segment.timestamp,
      }));

      // 関連動画のFeed情報（動画タイトル、動画URL（成型前）、サムネイルURL、チャンネル名）を取得
      try{
        if(this.publishOption != 'draft'){
          await this.fetchRelatedVideoInfo('confirm','none');
        }else{
          //下書き保存の時は取得せず、Feedも保存しない。
          this.relatedVideoFeeds = [];
        }
      }catch(error){
        console.error(error);
        this.isSubmitting = false;
        return;
      }

      let postData = {
        article_id: this.articleId,
        article_title: this.selectedTitle === 'custom' ? this.customTitle : this.selectedTitle,
        article_category: this.selectedCategory,
        publish_option: this.publishOption,
        article_body: this.articleBody,
        video_chapter: JSON.stringify({
          segment: segmentsToSave // ここで必要なキーだけを使用
        }),
        article_related_video_url: JSON.stringify({"urls": this.inputUrls}),
        article_related_video_info: JSON.stringify({"segment":this.relatedVideoFeeds}),
        video_status_display: this.articleUrl !== '' ?  VIDEO_STATUS_PUBLISHED : '',
      };


      // 予約公開の場合、scheduledTimeをISO 8601形式で送信
      if (this.publishOption === 'schedule' && this.scheduledTime) {
        // Vue.jsのdatetime-local入力から得られる値は"YYYY-MM-DDTHH:MM"形式であるため、
        // これをISO 8601形式に変換（"YYYY-MM-DDTHH:MM:SSZ"形式への変換が必要な場合）
        postData['scheduled_time'] = `${this.scheduledTime}:00+09:00`;
      }

      axios.post(`${apiEndpoint}/articlesetting/`, postData, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      }).then(response => {
        console.log(response);
        this.$router.push({
          name: 'ArticleManagement'
        });
      }).catch(error => {
        console.error(error);
      }).finally( () => {
        this.isSubmitting = false;
      }) ;
    },
    openModal() {
      this.isInfoModalOpen = true;
      document.documentElement.style.setProperty('overflow-y', 'hidden', 'important');
    },
    closeModal() {
      this.isInfoModalOpen = false;
      document.documentElement.style.removeProperty('overflow-y');
    },
    openPreviewModal() {
      this.isPreviewModalOpen = true;
    },
    closePreviewModal() {
      this.isPreviewModalOpen = false;
    },

    convertTimestampToSeconds(timestamp) {
      const parts = timestamp.split(':').reverse();
      let seconds = 0;
      for (let i = 0; i < parts.length; i++) {
        seconds += parseInt(parts[i], 10) * Math.pow(60, i);
      }
      return seconds;
    },


    validateNewTitle() {
      this.isNewTitleValid = this.newTitle.trim() !== '';
      this.updateAddButtonState();
    },
    validateNewTimestamp() {
      this.isNewTimestampValid = this.validateTimestamp(this.newTimestamp);
      this.updateAddButtonState();
    },
    updateAddButtonState() {
      this.isAddValid = this.isNewTitleValid && this.isNewTimestampValid && this.displayTimestamp !== '--:--:--';
    },
    validateNewTimestampOnInput(event) {
      this.newTimestamp = event.target.value;
      this.validateNewTimestamp();
    },

    handleTimePickerChange(value) {
      this.newTimestamp = value;
      this.displayTimestamp = value;
    },
    handleTimestampInput() {
      this.isNewTimestampValid = this.validateTimestamp(this.newTimestamp);
    },
    addTimestamp() {
      if (this.isAddValid) {
        this.videoSegments.push({
          timestamp: this.newTimestamp,
          title: this.newTitle,
          newTimestamp: this.newTimestamp,
          editing: false,
          isValid: true,
          showTimePicker: false,
          isActive: false,
        });
        this.newTimestamp = '00:00:00';
        this.displayTimestamp = '--:--:--';
        this.newTitle = '';
        this.newTimestampEditing = false;
        this.isAddValid = false;
      }
    },
    showNewTimestampEdit() {
      this.newTimestampEditing = true;
      this.isNewTitleValid = this.newTitle.trim() !== '';
      this.validateNewTimestamp();
    },

    closeTimestampEdit(index) {
      this.videoSegments[index].editing = false;
    },
    closeNewTimestampEdit() {
      this.newTimestampEditing = false;
    },


    confirmNewTimestamp() {
      if (this.isNewTimestampValid) {
        this.newTimestampEditing = false;
        this.displayTimestamp = this.newTimestamp;
        this.updateAddButtonState();
      }
    },
    toggleNewTimePicker() {
      this.newTimePickerVisible = !this.newTimePickerVisible;
    },
    hideNewTimePicker() {
      this.newTimePickerVisible = false;
    },

    setVideoStart(timestamp) {
      const seconds = this.convertTimestampToSeconds(timestamp);
      this.youtubeEmbedUrl = `https://www.youtube.com/embed/${this.youtubeVideoId}?start=${seconds}&autoplay=1&mute=1`;
    },
    deleteTimestamp(index) {
      this.videoSegments.splice(index, 1);
    },

    showEditBlock(index) {
      this.videoSegments[index].editing = true;
      this.videoSegments[index].isValid = this.validateTimestamp(this.videoSegments[index].newTimestamp);
    },

    hideEditBlock(index) {
      setTimeout(() => {
        if (!this.isMouseOverEditBlock) {
          this.videoSegments[index].editing = false;
          this.videoSegments[index].showTimePicker = false;
        }
      }, 200); // 短い遅延を設けることでマウス移動を検出
    },
    keepEditBlock(index) {
      this.isMouseOverEditBlock = true;
      this.videoSegments[index].editing = true;
    },

    updateTimestamp(index) {
      // バリデーションが成功した場合にのみタイムスタンプを更新
      if (this.videoSegments[index].isValid) {
        this.videoSegments[index].timestamp = this.videoSegments[index].newTimestamp;
        this.videoSegments[index].editing = false;
      } else {
        this.errorMessage = 'タイムスタンプに無効な文字が含まれています。';
      }
    },
    setSelectedTime() {
      if (this.selectedSegmentIndex !== null) {
        this.videoSegments[this.selectedSegmentIndex].newTimestamp = this.selectedTime;
        this.dialog = false;
      }
    },
    validateTimestampOnInput(index) {
      const newTimestamp = this.videoSegments[index].newTimestamp;
      this.videoSegments[index].isValid = this.validateTimestamp(newTimestamp);
    },
    handleClickOutside(event) {
      let isClickInside = false;
      for (let i = 0; i < this.videoSegments.length; i++) {
        const editBlockArray = this.$refs['editBlock' + i];
        if (editBlockArray) {
          if (Array.isArray(editBlockArray)) {
            for (const editBlock of editBlockArray) {
              if (editBlock && editBlock.contains(event.target)) {
                isClickInside = true;
                break;
              }
            }
          } else if (editBlockArray.contains(event.target)) {
            isClickInside = true;
            break;
          }
        }
      }
      if (!isClickInside) {
        this.closeEditBlock();
      }
    },

    closeEditBlock() {
      this.videoSegments.forEach(segment => {
        segment.editing = false;
      });
    },

    validateTimestamp(timestamp) {
      // 正しい形式のチェック（半角数字のみ許可）
      const regex = /^([01][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/;
      // 全角文字が含まれていないかチェック（全角数字や全角コロンを含まない）
      const isFullWidth = /[０-９：]/.test(timestamp);
      // バリデーション条件を満たしているかを確認
      return regex.test(timestamp) && !isFullWidth;
    },
    bringToFront(index) {
      this.videoSegments.forEach((element, i) => {
        element.isActive = (i === index);
      });
    },
    toggleTimePicker(index) {
      this.videoSegments[index].showTimePicker = !this.videoSegments[index].showTimePicker;
    },
    showTimePicker(index) {
      this.videoSegments[index].showTimePicker = true;
    },
    hideTimePicker(index) {
      this.videoSegments[index].showTimePicker = false;
    },
    setNewSelectedTime(time) {
      this.updateTimestamp(time);
    },
    // エラーメッセージをクリアする関数
    clearErrorMessage() {
      this.errorMessage = '';
    },
  }
};
</script>

<style scoped>
.ghost {
  opacity: 0.5;
}
</style>
