<template>

<Information/>

<div class="bg-white p-8 w-11/12 mx-auto mt-6">
    <!-- エラーメッセージ -->
    <div v-if="errorMessage" class="alert-error" role="alert">
        <img :src="alertIcon" alt="alert icon" class="w-4 h-4 mt-1">
        {{ errorMessage }}
        <button type="button" class="alert-close" @click="clearErrorMessage">
            <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <title>Close</title>
                <path d="M14.348 14.849a1 1 0 0 1-1.414 0L10 11.414 7.056 14.349a1 1 0 1 1-1.414-1.414L8.586 10 5.642 7.056a1 1 0 0 1 1.414-1.414L10 8.586l2.944-2.944a1 1 0 0 1 1.414 1.414L11.414 10l2.934 2.935a1 1 0 0 1 0 1.414z" />
            </svg>
        </button>
    </div>
    <div class="mb-4">
        <h2 class="text-2xl font-bold text-left text-gray-700">記事管理</h2>
    </div>
    <div v-if="articles.length === 0" class="text-center p-4">
        <img :src="notuploadedvideo_articleImg" alt="アイキャッチ" class="w-48 h-48 m-auto">
        <p class="mt-2">文字起こしされた記事はありません。<br>「<router-link to="/video-management" class="text-emerald-500">動画管理</router-link>」から動画のアップロードを開始してください。</p>
    </div>
    <div v-else>
        <div class="flex flex-wrap mb-4">
            <button v-for="status in articleStatuses" :key="status.value"
                @click="filterArticles(status.value, status.text)"
                :class="{
                    'bg-emerald-600 text-white': status.value === currentFilter,
                    'bg-gray-100 text-black': status.value !== currentFilter
                }"
                class="rounded-lg px-4 py-2 m-0.5 transition-colors duration-150">
                {{ status.text }}
                <span v-if="status.value === 'unpublished' && unpublishedCount > 0" class="bg-blue-500 text-white rounded-full px-2 py-1 text-xs">{{ unpublishedCount }}</span>
            </button>
        </div>
        <div class="overflow-x-auto">
            <table class="min-w-full text-sm text-gray-500">
                <thead class="text-xs text-gray-700 uppercase bg-transparent border-b-2">
                    <tr>
                        <th class="py-3 px-6 text-center whitespace-nowrap">文字起こし記事</th>
                        <th class="py-3 px-6 text-center whitespace-nowrap">公開状態</th>
                        <th class="py-3 px-6 text-center whitespace-nowrap">公開設定</th>
                        <th class="py-3 px-6 text-center whitespace-nowrap">記事URL</th>
                        <th class="py-3 px-6 text-center whitespace-nowrap">操作</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="filteredArticles.length === 0">
                        <td colspan="5" class="text-center py-4">該当する記事がありません。</td>
                    </tr>
                    <tr v-else v-for="article in filteredArticles" :key="article.id">
                        <td class="py-4 px-6">
                            <div class="flex items-center space-x-3">
                                <div class="w-40">
                                    <div class="flex-shrink-0 aspect-w-16 aspect-h-9">
                                        <img :src="article.video.thumbnail" alt="サムネイル" class="object-cover w-full h-full rounded">
                                    </div>
                                </div>
                                <div class="text-gray-900 overflow-hidden overflow-ellipsis whitespace-nowrap">
                                    {{ article.article_title || article.video.video_title_youtube }}
                                </div>
                            </div>
                        </td>
                        <td class="text-center whitespace-nowrap py-4 px-6">{{ article.video_status_display }}</td>

                        <td class="text-center whitespace-nowrap py-4 px-6">
                            <!--下書き-->
                            <button v-if="article.video_status_display === VIDEO_STATUS_PRE_PUBLICATION" @click="navigateToSettings(article.id)" class="h-8 relative btn-secondary btn-sm" style="width: 104px;">
                                設定する
                                <span class="absolute -top-1 -right-1 block h-2 w-2 bg-blue-600 rounded-full"></span>
                            </button>
                            <!--予約投稿中-->
                            <button v-else-if="article.video_status_display === VIDEO_STATUS_SCHEDULED" @click="navigateToSettings(article.id)" class="h-8 relative btn-secondary btn-sm" style="width: 104px;">
                                変更する
                            </button>
                            <span v-else>-</span>
                        </td>
                        <td class="text-center whitespace-nowrap py-4 px-6">
                            <a v-if="article.article_url" :href="article.article_url" class="text-emerald-500 hover:underline" >{{ article.article_url }}</a>
                            <span v-else>-</span>
                        </td>
                        <td class="text-center whitespace-nowrap py-4 px-6">
                            <button @click="toggleMenu(article.id)" class="w-6 rounded-full hover:bg-gray-100 grid justify-items-center">
                                <img :src="threepointleaderIcn" alt="メニュー">
                            </button>
                            <div v-if="isMenuOpen(article.id)" class="absolute right-0 mt-2 w-48 bg-white shadow-xl z-10">
                                <ul class="py-1">
                                    <li>
                                        <div v-if="canArticleUpdate">
                                          <button @click="navigateToSettings(article.id)" 
                                              class="text-gray-600 block px-4 py-2 text-sm" 
                                              :class="{'opacity-50 cursor-not-allowed'
                                              : article.video_status_display !== VIDEO_STATUS_PRE_PUBLICATION && article.video_status_display !== VIDEO_STATUS_SCHEDULED && article.video_status_display !== VIDEO_STATUS_PUBLISHED}" 
                                              :disabled="article.video_status_display !== VIDEO_STATUS_PRE_PUBLICATION && article.video_status_display !== VIDEO_STATUS_SCHEDULED && article.video_status_display !== VIDEO_STATUS_PUBLISHED">
                                              記事編集
                                          </button>
                                        </div>
                                        <button @click="openDeleteModal(article.id)"
                                            class="text-red-600 block px-4 py-2 text-sm"
                                            :class="{'opacity-50 cursor-not-allowed': article.video_status_display !== VIDEO_STATUS_PUBLISHED}"
                                            :disabled="article.video_status_display !== VIDEO_STATUS_PUBLISHED">
                                            掲載取りやめ
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <!--モーダル-->
    <div v-if="isModalOpen" class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" @click.self="closeModal()">
        <div class="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
            <div class="mt-3 text-center">
                <h3 class="text-lg leading-6 font-medium text-gray-900">「{{ selectedArticleTitle }}」を削除します。よろしいですか？</h3>
                <div class="mt-2 px-7 py-3">
                    <p class="text-sm text-gray-500">記事が削除されるまで最大で15分かかる場合があります。<br>「削除する」を押すと取り消しは出来ません。</p>
                </div>
                <div class="items-center px-4 py-3">
                    <button @click="closeModal()" class="btn-secondary">キャンセル</button>
                    <button @click="deleteArticle()" class="ml-3 px-4 py-2 bg-red-600 text-white text-base font-medium rounded-md w-auto shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500">削除する</button>
                </div>
            </div>
            <div class="absolute top-0 right-0 pt-4 pr-4">
                <button @click="closeModal()" class="text-gray-400 hover:text-gray-500 focus:outline-none">
                    <span>✕</span>
                </button>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import axios from 'axios';
import notuploadedvideo_articleImg from '@/assets/notuploadedvideo_article.png';
import threepointleaderIcn from '@/assets/threepointleader.svg';
import alertIcon from '@/assets/alert.svg';
import {
    VIDEO_STATUS_PRE_PUBLICATION,
    VIDEO_STATUS_SCHEDULED,
    VIDEO_STATUS_PUBLISHED
} from '@/plugins/constants';
import Information from './InformationTable.vue';

export default {
    name: 'ArticleManagement',
    components: {
        Information,
    },
    data() {
        return {
            articles: [],
            filteredArticles: [],
            articleStatuses: [{
                    text: 'すべて',
                    value: 'all'
                },
                {
                    text: VIDEO_STATUS_PRE_PUBLICATION,
                    value: 'pre_publication'
                },
                {
                    text: VIDEO_STATUS_SCHEDULED,
                    value: 'scheduled'
                },
                {
                    text: VIDEO_STATUS_PUBLISHED,
                    value: 'published'
                }
            ],
            VIDEO_STATUS_PRE_PUBLICATION,
            VIDEO_STATUS_SCHEDULED,
            VIDEO_STATUS_PUBLISHED,
            unpublishedCount: 0,
            notuploadedvideo_articleImg,
            currentFilter: 'all', // 現在選択中のフィルタ
            currentPage: 1, // 現在のページ
            articlesPerPage: 10, // ページごとの記事数
            threepointleaderIcn,
            openMenuId: null,
            isModalOpen: false,
            selectedArticleTitle: '', // 選択された記事のタイトルを保持するプロパティ
            errorMessage: '', // エラーメッセージを保持する変数
            alertIcon,
            canArticleUpdate: true,
        };
    },
    created() {
        this.fetchArticles();
    },
    methods: {
        fetchArticles() {
            const accessToken = this.$store.state.accessToken;
            const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;
            axios.get(`${apiEndpoint}/articles/`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                })
                .then(response => {
                    this.articles = response.data;
                    this.filteredArticles = this.articles;
                    this.unpublishedCount = this.articles.filter(article => article.status_upload === 'unpublished').length;
                })
                .catch(error => {
                    console.log(error);
                    this.errorMessage = '記事の取得に失敗しました。画面の再読み込みを行うと解決する場合があります。'; // エラーメッセージをセット
                });
            // 記事編集ON/OFF判定
            const articleUpdateFlg = process.env.VUE_APP_NEWS_UPDATE_ONOFF;
            if (articleUpdateFlg == 'OFF') {
                this.canArticleUpdate = false;
            }
        },

        clearErrorMessage() {
            this.errorMessage = '';
        },

        filterArticles(selectedValue, selectedText) {
            this.currentFilter = selectedValue;

            if (selectedValue === 'all') {
                this.filteredArticles = this.articles;
            } else {
                this.filteredArticles = this.articles.filter(article => article.video_status_display === selectedText);
            }
        },

        navigateToSettings(articleId) {
            this.$router.push({
                name: 'ArticleSettings',
                params: {
                    id: articleId
                }
            });
        },

        requestDelete(articleVideo) {
            const accessToken = this.$store.state.accessToken;
            const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;
            let postData = {
                video_id: articleVideo.video_id,
            };
            console.log(articleVideo);
            axios.post(`${apiEndpoint}/request-delete/`, postData, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                })
                .then(response => {
                    console.log(response);
                    this.$router.push({
                        name: 'ArticleManagement'
                    }).then(() => {
                        this.fetchArticles();
                    });
                })
                .catch(error => {
                    console.error(error);
                });
        },
        toggleMenu(articleId) {
            this.openMenuId = this.openMenuId === articleId ? null : articleId;
        },
        isMenuOpen(articleId) {
            return this.openMenuId === articleId;
        },
        openDeleteModal(articleId) {
            const article = this.articles.find(article => article.id === articleId);
            if (article) {
                this.selectedArticleTitle = article.article_title; // 記事のタイトルをセット
                this.selectedArticle = article; // 記事データをセット
            }
            this.isModalOpen = true;
        },
        closeModal() {
            this.isModalOpen = false;
            this.$router.push({
                name: 'ArticleManagement'
            }).then(() => {
                this.fetchArticles();
            });
        },
        deleteArticle() {
            if (this.selectedArticle && this.selectedArticle.video) {
                this.requestDelete(this.selectedArticle.video);
                this.toggleMenu(this.selectedArticle.id); 
                this.closeModal();

            }
        },

    }
};
</script>
