import axios from 'axios';
import { isLogin } from '../composables/userCheckAuth'
import router from '../router';
import store from '../store';

// トークンリフレッシュ用のエンドポイント
const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;
const TOKEN_REFRESH_ENDPOINT = `${apiEndpoint}/refresh-token/`; //'http://127.0.0.1:8000/api/v1/refresh-token/'

//Axiosを使用したリクエストをする前にログイン状態をチェック（ログイン処理の時は実施しない）
axios.interceptors.request.use(config => {
    if (config.url.includes('user-auth/') || isLogin()) {
        return config;
    } else {
        router.push('/login');
        return Promise.reject(new Error('Not logged in'));
    }
}, error => {
    return Promise.reject(error);
});

//Axiosを使用したリクエストの結果に対する処理（全てのリクエストが対象）
axios.interceptors.response.use(
    response => response, // 正常レスポンスの場合はそのまま返す
    async error => {
        const originalRequest = error.config;

        if (error.message === 'Not logged in') {
            return Promise.reject(error);
        }

        if (originalRequest.url === TOKEN_REFRESH_ENDPOINT) {
            store.commit('setAuthentication', false);
            store.commit('setTokenExpired', true);
            return Promise.reject(error);
        }

        // 401または403エラーで、かつリフレッシュトークンのリクエストでない場合にトークンリフレッシュを試みる
        if ((error.response.status === 401 || error.response.status === 403) && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
                // トークンリフレッシュのアクションを呼び出し、新しいトークンを取得
                const newToken = await store.dispatch('refreshToken');
                // 新しいトークンでリクエストのAuthorizationヘッダーを更新
                axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
                originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
                console.log("JWTリフレッシュ成功");
                // リフレッシュ成功後、元のリクエストを再送信
                return axios(originalRequest);
            } catch (refreshError) {
                // トークンリフレッシュ失敗時の処理
                console.error('JWTリフレッシュ失敗:', error);
                store.dispatch('logout');
                router.push('/login');
                return Promise.reject(refreshError);
            }
        }
        // その他のエラーはそのまま返す
        return Promise.reject(error);
    }
);

// Axios インターセプタの設定
/*axios.interceptors.response.use(
  response => response, // 正常レスポンスの場合はそのまま返す
  async error => {
    const originalRequest = error.config;

    // リフレッシュトークンのリクエストが失敗した場合、またはリフレッシュトークン自体が期限切れの場合
    if (originalRequest.url === TOKEN_REFRESH_ENDPOINT) {
      store.commit('setAuthentication', false);
      store.commit('setTokenExpired', true);
      return Promise.reject(error);
    }

    // 401または403エラーで、かつリフレッシュトークンのリクエストでない場合にトークンリフレッシュを試みる
    if ((error.response.status === 401 || error.response.status === 403) && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        // トークンリフレッシュのアクションを呼び出す
        await store.dispatch('refreshToken');
        // リフレッシュ成功後、元のリクエストを再送信
        return axios(originalRequest);
      } catch (refreshError) {
        // トークンリフレッシュ失敗時の処理
        store.commit('setAuthentication', false);
        store.commit('setTokenExpired', true);
        return Promise.reject(refreshError);
      }
    }

    // その他のエラーはそのまま返す
    return Promise.reject(error);
  }
);*/