<template>

    <!-- お知らせ一覧 -->
    <div v-if="isInformationPage" class="py-8 px-6 mx-auto gap-4">
        <div class="bg-white py-12 px-8 flex flex-col gap-4">
            <div class="flex pb-2 gap-[0.625rem]">
                <h2 class="text-2xl font-bold text-gray-700">運営からのお知らせ</h2>
            </div>
            <div class="flex flex-col w-full gap-2">
                <table class="text-left custom-border-b">
                    <tbody>
                        <tr v-if="informations.length == 0">
                            <td class="py-4 px-3 border-none">
                                <span class="font-light text-xs text-center">現在、お知らせはありません。</span>
                            </td>
                        </tr>
                        <tr v-else v-for="info in informations" :key="info.information_id">
                            <td class="w-[4.6875rem] py-4 px-3 font-light text-gray-700 whitespace-nowrap align-top border-t border-solid border-gray-300">
                                <span class="text-xs">{{ info.start_datetime }}</span>
                            </td>
                            <td class="py-4 px-3 border-t border-solid border-gray-300">
                                <router-link :to="`/information/details/${info.information_id}`" class="font-light text-sm text-left text-blue-600">
                                    <span>{{ info.title }}</span>
                                </router-link>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <!-- ページャー -->
                <div v-if="informations.length != 0" class="flex justify-end items-center py-2 px-3 gap-4">
                    <div class="font-light text-sm leading-tight tracking-normal flex justify-center items-center text-gray-500">
                        <span>{{ pageStartNum }}~</span>
                        <span v-if="!isMax">{{ pageEndNum }}</span>
                        <span v-else>{{ total }}</span>
                        <span>/{{ total }}</span>
                    </div>
                    <div class="flex gap-8">
                        <button @click="fetchInformations('back')" :disabled="isDisabled_BackBtn" class="flex justify-center items-center transform rotate-180">
                            <img :src="vectorIcnBack" class="w-[0.47125rem] h-[0.875rem]">
                        </button>
                        <button @click="fetchInformations('next')" :disabled="isDisabled_NextBtn" class="flex justify-center items-center">
                            <img :src="vectorIcnNext" class="w-[0.47125rem] h-[0.875rem]">
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- TOP・各管理画面 -->
    <div v-else>
        <div v-if="informations.length != 0" class="mt-8 w-11/12 mx-auto">
            <div class="bg-white py-4 px-8 flex flex-col gap-2">
                <h6 class="text-base font-bold text-gray-700">運営からのお知らせ</h6>
                <div>
                    <table class="text-left">
                        <tbody>
                            <tr v-for="info in informations" :key="info.information_id">
                                <td class="py-1 font-light text-gray-700 whitespace-nowrap align-top pr-2 leading-5">
                                    <span class="text-xs">{{ info.start_datetime }}</span>
                                </td>
                                <td class="py-1 align-top leading-5">
                                    <router-link :to="`/information/details/${info.information_id}`" class="text-sm font-light text-left text-blue-600">
                                        {{ info.title }}
                                    </router-link>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>




<script setup>
    import { ref, onMounted, getCurrentInstance}  from 'vue';
    import store from '../store';
    import axios from 'axios';
    import vectorIcnTrue from '@/assets/vector_true.svg';
    import vectorIcnFalse from '@/assets/vector_false.svg';

    const isInformationPage = ref(true);
    const action = ref('pager');
    const informations = ref([]);
    const pageStartNum = ref(1);
    const pageEndNum = ref(20);
    const total = ref(0);
    const isMax = ref(false);
    const vectorIcnBack = ref(vectorIcnFalse);
    const vectorIcnNext = ref(vectorIcnTrue);
    const isDisabled_BackBtn = ref(false);
    const isDisabled_NextBtn = ref(false);

    onMounted(() => {
        //表示制御
        const instance = getCurrentInstance();
        const parentName = instance?.parent?.type.name;
        if(parentName == 'RouterView'){
            isInformationPage.value = true;
            action.value = 'pager'; //非表示以外のお知らせを取得
        }else{
            isInformationPage.value = false;
            action.value = 'publication'; //非表示以外かつ掲載期間中のお知らせを取得
        }
        //データ取得
        fetchInformations('open');
    });

    const fetchInformations = (timing) => {
        const accessToken = store.state.accessToken;
        const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;
        let postData = {
            action: action.value,
            pageStartNum: timing == 'open' ? pageStartNum.value : timing == 'next' ? pageStartNum.value + 20 : pageStartNum.value - 20,
            pageEndNum: timing == 'open' ? pageEndNum.value : timing == 'next' ? pageEndNum.value + 20 : pageEndNum.value - 20
        };
        axios.post(`${apiEndpoint}/informations/`, postData,{
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        }).then(response => {
            informations.value = response.data.informations;
            updatePageNumber(response.data.total, response.data.start, response.data.end, response.data.isMax);
            const isBackBtnStatus = pageStartNum.value != 1 ? true : false;
            const isNextBtnStatus = isMax.value == true ? false : total.value <= 20 ? false : true; //上限まで検索した時は非活性にする。
            updatePageBtn(isBackBtnStatus, isNextBtnStatus);
        }).catch(error => {
            console.log(error);
        });
    };

    const updatePageNumber = (totalNum, start, end, isMaxBool) => {
        pageStartNum.value = start
        pageEndNum.value = end
        total.value = totalNum;
        isMax.value = isMaxBool;
    };

    const updatePageBtn = (isBuckBtnStatus, isNextBtnStatus) => {
        // <ボタン
        vectorIcnBack.value = isBuckBtnStatus == true ? vectorIcnTrue : vectorIcnFalse;
        isDisabled_BackBtn.value = !isBuckBtnStatus;
        // >ボタン
        vectorIcnNext.value = isNextBtnStatus == true ? vectorIcnTrue : vectorIcnFalse;
        isDisabled_NextBtn.value = !isNextBtnStatus;
    }

</script>

<style>
.custom-border-b tbody tr:last-child td {
  border-bottom: 1px solid #E5E7EB; /* ボーダーの色を指定 */
}
</style>