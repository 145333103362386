<template>
<div class="max-w-4xl mx-auto py-6">
    <!-- アカウント作成セクション -->
    <div class="bg-white shadow overflow-hidden sm:rounded-lg p-8">
        <!-- エラーメッセージ -->
        <div v-if="errorMessage" class="alert-error mb-4">
            {{ errorMessage }}
            <button type="button" class="alert-close" @click="errorMessage = ''">
                <span>&times;</span>
            </button>
        </div>
        <h3 class="text-lg font-bold leading-6 text-gray-900 text-center">
            アカウントを作成する
        </h3>
        <p class="mt-2 text-base text-gray-900">
            文字起こしされた記事に表示される内容です。アカウント作成後にチャンネル管理画面から変更可能です。
        </p>
        <div class="text-right">
            <a href="#" @click="showModal = true" class="text-sm text-emerald-600 hover:text-emerald-700">表示サンプルを確認する</a>
        </div>
        <div class="mt-6 flex items-center">
            <img :src="localChannelData.thumbnail_url" alt="Channel Thumbnail" class="w-16 h-16 object-cover rounded-full mr-4">
            <div class="break-all">
                <p class="text-sm font-bold text-gray-900">チャンネルURL</p>
                <p class="text-sm text-gray-900">{{ localChannelData.channel_url }}</p>
            </div>
        </div>
        <div class="mt-6">
            <label class="block text-sm font-bold text-gray-900">
                アカウント名 <span class="text-red-500">必須</span>
            </label>
            <input v-model="localChannelData.channel_name" type="text" placeholder="YouTubeと同じアカウント名" :class="[channelNameClass,'mt-1 block w-full rounded-lg border-2 border-gray-400 shadow-sm py-3 px-4']" />
            <div class="text-right text-sm text-gray-600">{{ localChannelData.channel_name.length }}/50</div>
            <p v-if="channelNameError" class="text-red-500 text-xs italic">{{ channelNameError }}</p>
        </div>
        <div class="mt-6">
            <label class="block text-sm font-bold text-gray-900">
                チャンネル説明
            </label>
            <textarea v-model="localChannelData.description" rows="5" placeholder="" :class="[descriptionClass,'mt-1 block w-full rounded-lg border-2 border-gray-400 shadow-sm py-4 px-4']"></textarea>
            <div class="text-right text-sm text-gray-600">{{ localChannelData.description.length }}/160</div>
            <p v-if="descriptionError" class="text-red-500 text-xs italic">{{ descriptionError }}</p>

        </div>

        <div class="mt-6">
            <p class=" mt-4 text-sm">ご登録前には当サービスの
            <a href="/guideline" target="_blank" class="text-blue-500">「ガイドライン」</a>および、当社の<a href="https://www.livedoor.com/rules/" target="_blank" class="text-blue-500">「利用規約」</a>、<a href="https://www.livedoor.com/privacy/" target="_blank" class="text-blue-500">「プライバシーポリシー」</a> を必ずご確認頂き、同意の上、お申込ください。</p>
        </div>
        <div class="mt-6 text-center">
    <div class="bg-gray-100 p-4 rounded inline-flex items-center justify-center">
      <input type="checkbox" id="agreement" v-model="isAgreed" class="mr-2">
      <label for="agreement" class="text-gray-900 text-sm">利用規約およびプライバシーポリシーに同意する</label>
    </div>
  </div>
        <div class="mt-8 text-center">
            <button @click="createAccount" type="button" :disabled="!isAgreed || isSaving" :class="[isAgreed && !isSaving ? 'btn-primary text-white': 'disabled:btn-disabled']">
                保存する
            </button>
        </div>
    </div>
</div>
<!--サンプルモーダル-->
<div v-if="showModal" class="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center">
    <div class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full p-4 text-center">
        <div class="absolute top-0 right-0 pt-4 pr-4">
            <button @click="showModal = false" class="text-gray-400 hover:text-gray-500">
                <span class="sr-only">Close</span>
                ✕
            </button>
        </div>
        <h3 class="text-lg leading-6 font-medium text-gray-900 mb-4">
            表示サンプル
        </h3>
        <img :src="sampleImg" alt="Sample" class="w-80 h-64 object-cover mx-auto">
        <div class="mt-4">
            <button @click="showModal = false" class="py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-100">
                閉じる
            </button>
        </div>
    </div>
</div>
</template>

    
    
<script>
import {
    ref,
    computed,
    onMounted
} from 'vue';
import axios from 'axios';

import {
    useStore,
} from 'vuex';
import {
    useRouter
} from 'vue-router';
import sampleImg from '@/assets/sampleImg.png';

export default {

    setup() {
        const store = useStore();
        const router = useRouter();
        const showModal = ref(false);
        // 初期値を持つオブジェクトでlocalChannelDataを初期化
        const localChannelData = ref({
            channel_url: '',
            thumbnail_url: '',
            channel_name: '',
            description: ''
        });
        const errorMessage = ref('');
        const isSaving = ref(false); //保存押下状態の管理
        const isAgreed = ref(false); // 利用規約同意チェックボックスの状態

        // Vuexのstoreからデータを取得し、localChannelDataに反映
        onMounted(() => {
            const channelData = store.state.channelData;
            if (channelData) {
                localChannelData.value = {
                    ...channelData
                };
            }
        });

        // エラーメッセージとクラス名の算出プロパティ
        const channelNameError = computed(() => {
            if (!localChannelData.value.channel_name) return '必須項目です。';
            if (localChannelData.value.channel_name.length > 50) return '50文字以内で入力してください。';
            return '';
        });

        const descriptionError = computed(() => {
            if (localChannelData.value.description.length > 160) return '160文字以内で入力してください。';
            return '';
        });

        const channelNameClass = computed(() => ({
            'border-red-500': channelNameError.value,
            'border-gray-300': !channelNameError.value,
        }));

        const descriptionClass = computed(() => ({
            'border-red-500': descriptionError.value,
            'border-gray-300': !descriptionError.value,
        }));

        // アカウント作成処理
        async function createAccount() {
            // エラーチェック
            if (channelNameError.value || descriptionError.value) {
                errorMessage.value = '入力エラーを修正してください。';
                return;
            }
            if (isSaving.value) {
                return; // 既に保存処理中の場合は何もしない
            }
            isSaving.value = true; // 保存処理を開始するときにボタンを無効化

            // APIリクエスト
            try {
                const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;
                const payload = {
                    channelData: localChannelData.value,
                    email: store.state.email,
                    token: store.state.token,
                    guidelineup: process.env.VUE_APP_GUIDELINE_UPDATE,
                    action: 'create',
                };
                const response = await axios.post(`${apiEndpoint}/user-auth/`, payload, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                if (response.status === 200) {
                    console.log('Account created successfully', response.data);
                    // 成功処理
                    await store.dispatch('login', { // 状態の更新が含まれるためawaitを使用
                        user_id: response.data.user_id,
                        accessToken: response.data.accessToken,
                        expiry: response.data.expiry,
                        guideline: JSON.stringify(response.data.userConsent)
                    });
                    await store.dispatch('clearregisterdata');
                    router.push({
                        name: 'AccountCreated'
                    });
                } else {
                    console.error('Failed to create account', response);
                }
            } catch (error) {
                console.error('Error creating account', error);
                errorMessage.value = 'アカウントの作成に失敗しました。';
            } finally {
                isSaving.value = false; // 処理が完了したらボタンを再び有効化
            }
        }

        return {
            showModal,
            localChannelData,
            createAccount,
            errorMessage,
            channelNameError,
            descriptionError,
            channelNameClass,
            descriptionClass,
            sampleImg,
            isSaving,
            isAgreed
        };
    }
};
</script>
