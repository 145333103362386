import store from '../store';

export function isGuideline() {

    const jsonStr = JSON.parse(store.state.guideline);
    const guidelineup = process.env.VUE_APP_GUIDELINE_UPDATE;

    if (jsonStr.guideline == guidelineup) {
        return true;
    } else {
        return false;
    }
}