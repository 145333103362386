<template>
  <div class="guideline">
    <div class="text-xs mb-4">
      <p>
        livedoor ECHOESガイドライン（以下「本ガイドライン」といいます。）は、株式会社ライブドア（以下「当社」といいます。）が提供するサービスであるlivedoor ECHOES（以下「本サービス」といいます。）の利用につき、当社が別途定める利用規約等の規約類（以下「利用規約等」といいます。）とあわせて適用されるものです。本サービスの利用には、本ガイドラインに承諾いただくことが必要です。
      </p>
      <p>
        当社は、当社が必要と判断した場合、本サービスの目的の範囲内で、本ガイドラインを変更することがあります。
      </p>
    </div>

    <h3 class="text-lg font-bold">本ガイドラインと利用規約等との関係</h3>
    <div class="text-xs mb-4">
      <p>
        本ガイドラインに規定がない場合、原則として、利用規約等の規定が適用されます。本ガイドラインの規定が利用規約等の規定と矛盾・抵触する場合、その矛盾・抵触の限りにおいて、本ガイドラインが優先して適用されます。
      </p>
    </div>

    <h3 class="text-lg font-bold">本サービスについて</h3>
    <div class="text-xs mb-4">
      <p>
        本サービスは当社が開発・運営するテキストコンテンツの自動生成・配信サービスです。元となるデータをアップロードするだけで、アップロードされたデータを基にしたテキストコンテンツを自動生成し、ライブドアニュースに配信することができます。
      </p>
      <p>
        当社は、当社の判断により、本サービスの提供を終了することがあります。本サービスの終了により本サービスのユーザー（以下「ユーザー」といいます。）に損害が生じたとしても、当社はその責任を負いません。
      </p>
    </div>

    <h3 class="text-lg font-bold">アップロードされたデータの利用について</h3>
    <div class="text-xs mb-4">
      <p class="guidelinetext">
        1.	当社利用規約（https://www.livedoor.com/rules/）1.3.1（利用者が掲載等を行ったデータの利用）は、ユーザーが本サービスにアップロードしたデータには適用しません。
      </p>
      <p class="guidelinetext">
        2.	ユーザーは、本サービスにデータをアップロードした場合、本サービスの利用に必要な範囲で、無償で当該データにかかる著作物の利用を許諾したものとみなします。また、ユーザーは、本サービスの利用において、著作者人格権の行使をしないものとします。
      </p>
      <p class="guidelinetext">
        3.	ユーザーが本サービスにアップロードしたデータ（以下「元データ」といいます）に関する著作権およびその他の権利は、すべてユーザーに帰属します。当社は、ユーザーの許諾なく元データを利用したり、有償・無償を問わず第三者に提供、販売、または公開することは行いません。
        ただし、本サービスにおいて生成されたコンテンツ（以下「生成コンテンツ」といいます。）についてはこの限りではなく、当社は生成コンテンツを本サービスの提供、関連サービスでの配信、および提携先への提供等に利用することができるものとします。
      </p>
    </div>

    <h3 class="text-lg font-bold">生成されたコンテンツの利用について</h3>
    <div class="text-xs mb-4">
      <p>
        ユーザーは、当社に対し、生成コンテンツに対して、譲渡不可かつ再許諾不可の、次の各号に定める非独占的権利を許諾します。
      </p>
      <p class="guidelinetext">
        1.	生成コンテンツをサーバーの記憶装置に蓄積・複製し、送信可能化し、第三者に対して自動公衆送信（以下「配信」といいます。）すること
      </p>
      <p class="guidelinetext">
        2.	ライブドアニュースをはじめとする当社サービス上において、生成コンテンツを紹介する際、提供趣旨が誤解されない範囲に限り、AIやその他の手段を用いて紹介文の作成や見出しの変更を行うこと
      </p>
      <p class="guidelinetext">
        3.	生成コンテンツを、ライブドアニュースをはじめるとする当社サービスやソーシャルネットワークサービス（Facebook、Ｘ（旧Twitter）、LINEアカウントを含みます）、ニュース配信サービス（Googleニュース、Google Discover等）において、複製、編集し、配信すること
      </p>
      <p class="guidelinetext">
        4.	当社が提携する外部メディアや第三者サービスに対し、有償または無償で提供・配信すること
      </p>
      <p class="guidelinetext">
        5.	生成コンテンツを配信する際に、当社サービスにおける生成コンテンツを掲載または紹介するページに、第三者の広告宣伝を表示すること
      </p>
    </div>

    <h3 class="text-lg font-bold">生成されたコンテンツの削除について</h3>
    <div class="text-xs mb-4">
      <p>
        ユーザーは、自身がアップロードした元データに基づく生成コンテンツについて削除を希望する場合、当社にその旨を通知することで、当該生成コンテンツの削除を要求することができます。当社は、合理的な期間内に削除の対応をするものとします。
      </p>
      <div class="max-w-[912px] max-h-[52x] p-2 gap-2 border-black border-[1px] border-solid">
        <div class="guidelinebox max-w-[896px] max-h-[36x]">
          本ガイドライン又は利用規約等に違反すると当社が判断した場合には、予告なく、本サービスを利用して生成されたコンテンツやアカウントを削除することがあります。当社は、生成コンテンツやアカウントの維持について何ら保証するものではなく、削除によりユーザーに損害が生じたとしてもその責任を負いません。
        </div>
      </div>
    </div>

    <h3 class="text-lg font-bold">禁止事項</h3>
    <div class="text-xs mb-4">
      <p>
        本サービスの利用においては、当社利用規約1.4（禁止行為）のほかに、サービスの健全な運営と持続可能性を確保するため、以下の行為を禁止します。
      </p>
      <p>
        1.	ライブドアニュースへの配信を目的としないコンテンツ生成の禁止
      </p>
      <p class="ml-3">
        本サービスは、ライブドアニュースへの配信を目的とするものです。ユーザーは、ライブドアニュースへの配信を目的とせずにコンテンツの生成をすることはできません。配信を目的としない生成が確認された場合又はユーザーが配信を目的していないと当社が判断した場合には、予告なく、生成コンテンツやアカウントの削除をいたします。
      </p>
      <p>
        2.	偽情報や法律に反するコンテンツの生成の禁止
      </p>
      <p class="ml-3">
        ユーザーは、偽情報や未検証の情報、法律に反する内容を含むデータをアップロードし、それに基づいたコンテンツを生成・配信することはできません。ユーザーは、配信するコンテンツが信頼性のある情報に基づくものであることを担保する責任を負います。
      </p>
      <p>
        3.	プライバシー侵害のあるデータの配信禁止
      </p>
      <p class="ml-3">
        ユーザーは、第三者の機密情報や個人情報を含むデータをアップロードし、それに基づいたコンテンツを生成・配信することはできません。ユーザーは、プライバシーに関わる情報の配信には、他者の権利を侵害しないように十分に配慮し、適切な措置を講じる義務を負います。
      </p>
      <p>
        4.	バイアスや差別的表現の生成の禁止
      </p>
      <p class="ml-3">
        ユーザーは、バイアスや差別的な表現を含むデータをアップロードし、それに基づいたコンテンツを生成・配信してはなりません。人権や多様性を尊重するコンテンツを配信する必要があります。
      </p>
      <p>
        5.	知的財産権の侵害の禁止
      </p>
      <p class="ml-3">
        ユーザーは、他人の著作物や知的財産権を侵害するデータをアップロードし、それに基づいたコンテンツを生成・配信してはなりません。ユーザーは、適切な権利処理をした上で知的財産権を取り扱わなければなりません。
      </p>
      <p>
        6.	内容を確認せずに配信する行為の禁止
      </p>
      <p class="ml-3">
        当社は、本サービスを通じて生成されたコンテンツの確実性、完全性、真実性、正確性、合法性等について一切保証するものではなく、いかなる責任も負いません。ユーザーは、生成されたコンテンツの内容に依存する場合、自己の責任においてその正確性や合法性を確認する必要があり、確認せずに配信することを禁止します。生成されたコンテンツは元の情報を正確に反映していない場合があるため、コンテンツの内容を慎重に確認し、必要に応じて元のデータと照らし合わせることが求められます。
      </p>
      <p>
        7.	プロンプトインジェクション等の禁止
      </p>
      <p class="ml-3">
        ユーザーは、プロンプトインジェクションやそれに類する行為を行ってはなりません。本サービスの正当な機能や出力に悪影響を与える試行や不正操作を行うことは厳禁です。
      </p>
      <p>
        8.	連携している外部サービスの利用規約違反の禁止
      </p>
      <p class="ml-3">
        本サービスはコンテンツの生成にあたって以下の外部サービスを利用しています。ユーザーは、本サービスが利用している外部サービスの利用規約に違反する行為を行ってはなりません。以下のサービスの利用規約を遵守する必要があります。
      </p>
      <p>
        ○	OpenAI：Terms & policies（https://openai.com/policies）
      </p>
    </div>
    <div class="text-xs">
      <p>
        制定：2025年3月18日
      </p>
    </div>
  </div>
</template>

<style scoped>
/* ガイドライン全体 */
.guideline {
  font-family: HelveticaNeue;
}
.guideline p {
  margin-bottom: 8px;
}
/* ガイドライン見出し (一括で設定する為、cssで設定) */
.text-lg {
  margin-bottom: 12px;
}
/* ガイドライン本文 (一括で設定する為、cssで設定)  */
.text-xs {
  line-height: 18px;
}
/* ガイドライン本文 段落 */
.guidelinetext {
  margin-left: 12px;
  text-indent: -12px;
}
/* 囲み枠 line-height不要の為、cssで設定*/
.guidelinebox {
  font-size: 12px;
}
</style>