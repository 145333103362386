<template>
    <div class="flex flex-col items-center justify-center min-h-screen mt-8 p-8 w-11/12 mx-auto bg-white">
        <h1 class="text-2xl font-bold text-center text-gray-800 mb-5">ページが見つかりませんでした</h1>
        <img :src="notfoundIcon" alt="Not Found" class="mb-5">
        <p class="text-gray-600 text-center mb-8">お探しのページは移動もしくは削除された可能性があります。</p>
        <button @click="goBack" class="btn-secondary">トップページに戻る</button>
    </div>
</template>

<script>
import { useRouter } from 'vue-router';
import notfoundIcon from '@/assets/404.svg';

export default {
    setup() {
        const router = useRouter();

        function goBack() {
            router.push({ name: 'Dashboard' });
        }

        return {
            notfoundIcon,
            goBack
        };
    }
}
</script>

<style scoped>
/* ここに追加のスタイリングを記述できます */
</style>
