<template>

<Information />

<div class="p-8 w-11/12 mx-auto bg-white mt-6">
    <!-- エラーメッセージ -->
    <div v-if="errorMessage" class="alert-error" role="alert">
        <img :src="alertIcon" alt="alert icon" class="w-4 h-4 mt-1">
        {{ errorMessage }}
        <button type="button" class="alert-close" @click="clearErrorMessage">
            <svg class="w-6 h-6" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <title>Close</title>
                <path d="M14.348 14.849a1 1 0 0 1-1.414 0L10 11.414 7.056 14.349a1 1 0 1 1-1.414-1.414L8.586 10 5.642 7.056a1 1 0 0 1 1.414-1.414L10 8.586l2.944-2.944a1 1 0 0 1 1.414 1.414L11.414 10l2.934 2.935a1 1 0 0 1 0 1.414z" />
            </svg>
        </button>
    </div>
    <!-- 文字起こし進捗アナウンス -->
    <div v-if="isTranscriptionBusy" class="bg-red-500 text-white p-4 flex justify-between items-center rounded">
        <p>ただいま文字起こし機能が混み合っており、完了まで通常よりお時間がかかる可能性がございます。</p>
        <button @click="isTranscriptionBusy = false" class="text-lg">&times;</button>
    </div>
    <!-- 動画アップロードボタンを右上に配置 -->
    <div class="flex justify-space-between pb-2">
        <span class="text-2xl font-bold text-left text-gray-700">動画管理</span>
        <button @click="openUploadModal" class="btn-primary">
            動画をアップロード
        </button>
    </div>

    <!-- ビデオデータの表表示 -->
    <div v-if="videos.length > 0" class="mt-6">
        <div class="overflow-x-auto">
            <table class="w-full text-sm text-gray-500">
                <thead class="text-xs text-gray-700 uppercase bg-transparent border-b">
                    <tr>
                        <th class="py-3 px-6 text-center">動画</th>
                        <th class="py-3 px-6 text-center">文字起こし進捗</th>
                        <th class="py-3 px-6 text-center">記事公開</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="video in videos" :key="video.id" class="bg-white border-b text-sm">
                        <td class="py-4 px-6 text-center">
                            <div class="flex items-center space-x-3">
                                <div class="w-40">
                                    <div class="flex-shrink-0 aspect-w-16 aspect-h-9">
                                        <img class="object-cover w-full h-full rounded" :src="video.thumbnail" alt="サムネイル" />
                                    </div>
                                </div>
                                <div class="text-gray-900 overflow-hidden text-ellipsis whitespace-nowrap max-w-xs sm:max-w-full">
                                    {{ video.video_title }}
                                </div>
                            </div>
                        </td>
                        <td class="py-4 px-6 text-center">
                            <!-- ステータスが100%の場合、ボタンを表示 -->
                            <div v-if="video.transcription_progress_display === '100%'">
                                <p class="mb-2">文字起こし完了</p>
                                <div v-if="canArticleUpdate">
                                    <button @click="goToArticleSettings(video.article_id)" class="btn-secondary btn-sm">
                                        記事公開設定へ
                                    </button>
                                </div>
                            </div>
                            <!-- 文字起こし進捗が0%の場合のボタン表示 -->
                            <div v-else-if="video.transcription_progress_display === '0%'">
                                <button @click="openAdditionalInfoModal(video.video_id)" class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                                    追加情報入力
                                </button>
                            </div>
                            <!-- それ以外の場合、ステータスの値を表示 -->
                            <div v-else>
                                {{ video.transcription_progress_display }}
                            </div>
                        </td>
                        <td class="py-4 px-6 text-center">{{ video.status_transcription_display }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div v-else class="text-center py-10">
        <img :src=notuploadedvideoImg alt="アイキャッチ" class="w-48 h-48 m-auto">
        <p class="text-gray-500 pt-6">アップロードされた動画はありません。<br>「<button @click="openUploadModal" class="text-blue-700">動画をアップロードする</button>」から動画のアップロードを開始してください。</p>
    </div>

    <!-- モーダル -->
    <div v-if="isUploadModalOpen" class="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
        <div class="bg-white rounded-lg w-full max-w-4xl max-h-128 p-10 relative">
            <p v-if="errorMessageModal" class="alert-error mb-4">
                {{ errorMessageModal }}
                <button type="button" class="alert-close" @click="clearErrorMessageModal">
                    <span>&times;</span>
                </button>
            </p>
            <div class="flex justify-between items-center border-b-2 pb-2">
                <h3 class="text-lg font-semibold text-left">動画をアップロードする</h3>
                <!-- モーダルを閉じるボタン -->
                <button v-if="!isUploading" @click="closeUploadModal" class="text-gray-700 hover:text-gray-900">
                    &times;
                </button>
            </div>

            <!-- アップロードエリア -->
            <div v-if="!selectedFile" @drop.prevent="handleDrop" @dragover.prevent="handleDragOver" @dragleave.prevent="handleDragLeave" class="mt-4 border-2 border-dashed border-gray-300 p-4 rounded-lg text-center relative" :class="{ 'bg-gray-100': dragOver }">
                <img :src="uploadIcn" alt="Upload" class="mx-auto h-12 w-12 text-gray-400">
                <p class="mt-2 text-gray-600">アップロードする動画ファイルをドラッグ＆ドロップしてください。</p>
                <div class="flex justify-center mt-2">
                    <ul class="text-sm text-gray-600 text-left" style="list-style: inside;">
                        <li>アップロードできる1ファイルの容量は5GBまでです。</li>
                        <li>mp4、mp3、およびm4aファイルに対応しています。</li>
                    </ul>
                </div>
                <button @click="triggerFileInput" class="mt-4 btn-primary">
                    ファイルを選択する
                </button>
                <input type="file" ref="fileInput" @change="handleFileUpload" accept="video/mp4,audio/mp3,audio/m4a,audio/x-m4a,audio/mpeg" class="hidden">
            </div>

            <!-- ファイル選択後の表示 -->
            <div v-else class="mt-4 text-center px-4">
                <div class="mx-auto max-w-full overflow-auto" style="max-height: 500px;">
                    <video v-if="videoPreview" controls class="min-w-full min-h-full">
                        <source :src="videoPreview" type="video/mp4">
                        お使いのブラウザはビデオタグをサポートしていません。
                    </video>
                </div>
                <div class="mt-4 bg-gray-100 p-4 rounded-lg">
                    <p class="text-gray-800">ファイル名：{{ selectedFile.name }}</p>
                </div>
                <ul class="mt-4 text-gray-600 text-left mx-auto" style="max-width: 500px; list-style: inside;">
                    <li>アップロードが完了したら、自動的に文字起こしが開始されます。</li>
                    <li>アップロード中は画面を閉じずにお待ちください。</li>
                </ul>
                <hr class="my-4">
                <div class="flex justify-end">
                    <button v-if="!isUploading || uploadError" @click="startUpload" class="btn-primary">
                        アップロードを開始
                    </button>
                </div>
                <p v-if="uploadError" class="text-red-500 mt-2">アップロードに失敗しました。もう一度お試しください。</p>
            </div>

            <!-- 追加入力欄 -->
            <div v-if="isUploading && isAdditionalInfoVisible && selectedFile" class="mt-4">
                <!-- 入力欄の内容 -->
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4">
                    <div class="bg-white rounded-lg p-8 w-full max-w-4xl relative">
                        <p v-if="errorMessageModal" class="alert-error mb-4">
                            {{ errorMessageModal }}
                            <button type="button" class="alert-close" @click="clearErrorMessageModal">
                                <span>&times;</span>
                            </button>
                        </p>
                        <div class="flex justify-between items-center border-b-2 pb-2 mb-4">
                            <h3 class="text-lg font-semibold">詳細情報を入力する</h3>
                            <!--<button @click="closeUploadModal" class="text-gray-700 hover:text-gray-900">&times;</button>-->
                        </div>
                        <div class="flex flex-wrap -mx-4">
                            <div class="w-full md:w-1/2 px-4 mb-4">
                                <div class="mb-4">
                                    <label class="block">YouTubeの動画URL <span class="text-red-500">必須</span></label>
                                    <input type="url" v-model="youtubeUrl" @blur="validateField('youtubeUrl')" placeholder="例）https://youtu.be/hQiVYWZPwCk" class="border p-2 rounded w-full" :class="{'border-red-500': errors.youtubeUrl}">
                                    <p v-if="showError('youtubeUrl')" class="text-red-500">{{ errors.youtubeUrl }}</p>
                                </div>
                                <div class="mb-4">
                                    <label class="block">YouTubeの動画タイトル <span class="text-red-500">必須</span></label>
                                    <textarea v-model="youtubeTitle" @blur="validateField('youtubeTitle')" placeholder="YouTubeと同じタイトルを入力してください" class="border p-2 rounded w-full" :class="{'border-red-500': errors.youtubeTitle}" rows="2" maxlength="100"></textarea>
                                    <p v-if="showError('youtubeTitle')" class="text-red-500">{{ errors.youtubeTitle }}</p>
                                    <div class="text-right">{{ youtubeTitle.length }}/100</div>
                                </div>
                                <div class="mb-4">
                                    <label class="block">動画内の発言者名 <span class="text-red-500">必須</span></label>
                                    <input type="text" v-model="speakerName" @blur="validateField('speakerName')" placeholder="発言者名を簡潔に入力してください" class="border p-2 rounded w-full" :class="{'border-red-500': errors.speakerName}">
                                    <p v-if="showError('speakerName')" class="text-red-500">{{ errors.speakerName }}</p>
                                </div>
                                <div class="mb-4">
                                    <label class="block">動画内の発言者の肩書</label>
                                    <input type="text" v-model="speakerTitle" placeholder="肩書きがある場合は入力してください" class="border p-2 rounded w-full">
                                </div>
                            </div>
                            <div class="w-full md:w-1/2 px-4 flex flex-col items-center">
                                <video v-if="videoPreview" controls class="w-full max-w-xs">
                                    <source :src="videoPreview" type="video/mp4">
                                    お使いのブラウザはビデオタグをサポートしていません。
                                </video>
                                <div class="bg-gray-100 p-4 rounded-lg mt-4">
                                    <p class="text-gray-800">ファイル名：{{ selectedFile.name }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="flex justify-end mt-4 relative">
                                <div class="relative" @mouseover="showMessage = true" @mouseleave="showMessage = false">
                                    <button :disabled="!canSubmit || uploadedFileId.value === null" @click="submitData" class="btn-primary disabled:btn-disabled">情報を送信</button>
                                    <!-- ホバーメッセージ -->
                                    <div v-if="showMessage==true && !canSubmit" class="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 bg-gray-700 text-white text-sm rounded-lg p-2" style="width: 200px; z-index: 50;">
                                        ファイルのアップロード完了後、情報を送信できるようになります。
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- アップロード成功モーダル -->
            <div v-if="isUploadSuccessModalOpen" class="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center">
                <div class="bg-white rounded-lg p-8 w-1/2 relative">
                    <img :src="uploadSuccessIcn" alt="Success" class="mx-auto" />
                    <h2 class="text-xl font-bold text-center my-4">アップロードが完了しました。</h2>
                    <p class="text-center mb-4">文字起こしが開始されました。閉じるを押して文字起こし完了までしばらくお待ちください。</p>
                    <div class="text-center">
                        <button @click="handleSuccessModalClose" class="btn-primary">
                            閉じる
                        </button>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <!-- 追加情報入力モーダル -->
    <div v-if="isAdditionalInfoModalOpen" class="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4">
        <div class="bg-white rounded-lg p-8 w-full max-w-4xl relative">
            <!-- ボタン無効化時の説明テキスト -->
            <div v-if="uploadedFileId.value === null" class="text-red-500 mt-2 text-center">
                動画のアップロードが完了するまで、追加情報は送信できません。
            </div>
            <p v-if="errorMessageModal" class="alert-error mb-4">
                {{ errorMessageModal }}
                <button type="button" class="alert-close" @click="clearErrorMessageModal">
                    <span>&times;</span>
                </button>
            </p>
            <div class="flex justify-between items-center border-b-2 pb-2 mb-4">
                <h3 class="text-lg font-semibold">詳細情報を入力する</h3>
                <button @click="closeAdditionalInfoModal" class="text-gray-700 hover:text-gray-900">&times;</button>
            </div>
            <!-- 入力欄の内容 -->
            <div class="mb-4">
                <label class="block">YouTube動画のURL <span class="text-red-500">必須</span></label>
                <input type="url" v-model="youtubeUrl" @blur="validateField('youtubeUrl')" placeholder="例）https://youtu.be/hQiVYWZPwCk" class="border p-2 rounded w-full" :class="{'border-red-500': errors.youtubeUrl}">
                <p v-if="showError('youtubeUrl')" class="text-red-500">{{ errors.youtubeUrl }}</p>
            </div>

            <div class="mb-4">
                <label class="block">YouTubeの動画タイトル <span class="text-red-500">必須</span></label>
                <textarea v-model="youtubeTitle" @blur="validateField('youtubeTitle')" placeholder="YouTubeと同じタイトルを入力してください" class="border p-2 rounded w-full" :class="{'border-red-500': errors.youtubeTitle}" rows="2" maxlength="100"></textarea>
                <p v-if="showError('youtubeTitle')" class="text-red-500">{{ errors.youtubeTitle }}</p>
                <div class="text-right">{{ youtubeTitle.length }}/100</div>
            </div>

            <div class="mb-4">
                <label class="block">動画内の発言者名 <span class="text-red-500">必須</span></label>
                <input type="text" v-model="speakerName" @blur="validateField('speakerName')" placeholder="発言者名を簡潔に入力してください" class="border p-2 rounded w-full" :class="{'border-red-500': errors.speakerName}">
                <p v-if="showError('speakerName')" class="text-red-500">{{ errors.speakerName }}</p>
            </div>

            <div class="mb-4">
                <label class="block">動画内の発言者の肩書</label>
                <input type="text" v-model="speakerTitle" placeholder="肩書きがある場合は入力してください" class="border p-2 rounded w-full">
            </div>

            <div class="flex justify-end mt-4">
                <button :disabled="!canSubmit || uploadedFileId.value === null" @click="submitAdditionalInfo" class="btn-primary disabled:btn-disabled">情報を送信</button>
            </div>
        </div>

    </div>

    <!-- ガイドラインが未同意時のポップアップウィンドウ -->
    <div v-if="isGuidelineOpen" class="fixed inset-0 bg-gray-500 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center z-50">
      <div class="popup bg-white rounded-lg w-full max-w-4xl min-w-xs max-h-[698px] mx-[8%] p-8 gap-3 border-[1px] border-solid relative" v-scroll-lock="isGuidelineOpen" @click.stop>
        <div class="mb-8 flex flex-col items-center justify-center">
            <span class="dli-exclamation-circle mb-4"><span></span></span>
            <h2 class="guidelineTitle text-xl font-bold break-words">livedoor ECHOESご利用ガイドライン</h2>
        </div>
        <div class="gap-2 text-xs mb-4">
            <h3 class="text-sm font-bold mb-2">ガイドラインが新たに作成されました。</h3>
            <p class="mb-2">
              ご登録されるには当サービスの「ガイドライン」をご確認後、下記「ガイドラインに同意する」ボタンにてご同意ください。
            </p>
        </div>
        <UserGuidelineBody class="max-w-[832px] max-h-[358px] mb-4 p-4 border-gray-300 border-[1px] border-solid overflow-y-scroll"/>
        <button @click="contentUpdate" class="bg-emerald-600 rounded text-white w-full max-w-[832px] h-full max-h-[48px] py-2 px-3 font-bold">同意する</button>
      </div>
    </div>

</div>
</template>

<script>
import {
    ref,
    computed,
    onMounted
} from 'vue';
import axios from 'axios';
import notuploadedvideoImg from '@/assets/notuploadedvideo.png';
import uploadSuccessIcn from '@/assets/success.svg';
import uploadIcn from '@/assets/uploadIcn.svg';
import alertIcon from '@/assets/alert.svg';
import UserGuidelineBody from './UserGuidelineBody.vue';
import Information from './InformationTable.vue';
import {
    useStore
} from 'vuex';
import {
    useRouter
} from 'vue-router';
export default {
    name: 'VideoManagement',
    components: {
        UserGuidelineBody,
        Information
    },
    setup() {
        const store = useStore();
        const router = useRouter();
        const videos = ref([]);
        const isUploadModalOpen = ref(false);
        const selectedFile = ref(null);
        const videoPreview = ref(null);
        let isUploading = ref(false);
        const uploadProgress = ref(0);
        const youtubeUrl = ref('');
        const youtubeTitle = ref('');
        const speakerName = ref('');
        const speakerTitle = ref('');
        let isAdditionalInfoVisible = ref(false);
        let uploadedFileId = ref(null);
        const dragOver = ref(false);
        let uploadError = ref(false);
        const errorMessage = ref('');
        const errorMessageModal = ref('');
        let isAdditionalInfoModalOpen = ref(false);
        const isUploadSuccessModalOpen = ref(false);
        const fileInput = ref(null); // ファイルインプットの参照
        const currentVideoId = ref(0);
        const isAdditionalInfoVisibl = ref('');
        const isSubmitting = ref(false);
        const showMessage = ref(false);
        let isGuidelineOpen = ref(false);
        let canArticleUpdate = ref(true);

        const errors = ref({
            youtubeUrl: null,
            youtubeTitle: null,
            speakerName: null,
        });

        const touched = ref({
            youtubeUrl: false,
            youtubeTitle: false,
            speakerName: false,
        });

        //追加情報validation (touch)
        const validateField = (fieldName) => {
            if (fieldName === 'youtubeUrl') {
                errors.value.youtubeUrl = !youtubeUrl.value ? '必須項目です。' : null;
            } else if (fieldName === 'youtubeTitle') {
                errors.value.youtubeTitle = !youtubeTitle.value ? '必須項目です。' : null;
                // YouTubeタイトルの文字数制限もここでチェック
                if (youtubeTitle.value && youtubeTitle.value.length > 100) {
                    errors.value.youtubeTitle = '文字数が超過しています。';
                }
            } else if (fieldName === 'speakerName') {
                errors.value.speakerName = !speakerName.value ? '必須項目です。' : null;
            }
            touched.value[fieldName] = true; // フィールドをタッチとしてマーク
        };

        // エラーを表示するべきかどうかを判断する関数
        const showError = (fieldName) => {
            return errors.value[fieldName] && touched.value[fieldName];
        };

        const canSubmit = computed(() => {
            // すべての必須フィールドに値が入力されているかどうかを確認
            return youtubeUrl.value && youtubeTitle.value && speakerName.value && !isSubmitting.value && uploadedFileId.value !== null;
        });

        // エラーメッセージをクリアする関数
        const clearErrorMessage = () => {
            errorMessage.value = '';
        };

        // エラーメッセージをクリアする関数（モーダル内）
        const clearErrorMessageModal = () => {
            errorMessageModal.value = '';
        };

        // ビデオデータの取得
        const fetchVideos = async () => {
            try {
                const accessToken = store.state.accessToken;
                const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;
                const response = await axios.get(`${apiEndpoint}/videos/`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });
                videos.value = response.data;
            } catch (error) {
                errorMessage.value = 'ビデオデータの取得中にエラーが発生しました。画面の再読み込みを行ってください。';
                console.error("ビデオデータの取得中にエラーが発生しました。", error);
            }
            // ガイドライン同意判定
            const jsonStr = JSON.parse(store.state.guideline);
            const guidelineup = process.env.VUE_APP_GUIDELINE_UPDATE;
            if (jsonStr.guideline !== guidelineup) {
                isGuidelineOpen.value = true;
                document.documentElement.style.setProperty('overflow-y', 'hidden', 'important');
            }
            // 記事編集ON/OFF判定
            const articleUpdateFlg = process.env.VUE_APP_NEWS_UPDATE_ONOFF;
            if (articleUpdateFlg == 'OFF') {
                canArticleUpdate.value = false;
            }
        };

        onMounted(fetchVideos);

        // 進捗が混雑しているかどうかの判定
        const isTranscriptionBusy = computed(() => {
            const transcriptionInProgress = videos.value.filter(video => {
                const progress = parseFloat(video.transcription_progress_display);
                return progress > 0 && progress < 100;
            }).length;
            return transcriptionInProgress >= 10;
        });

        // ドラッグ&ドロップ関連の処理
        const handleDragOver = (event) => {
            event.preventDefault();
            dragOver.value = true;
        };

        const handleDragLeave = () => {
            dragOver.value = false;
        };

        const handleDrop = (event) => {
            event.preventDefault();
            dragOver.value = false;
            if (event.dataTransfer.files.length) {
                processFile(event.dataTransfer.files[0]);
            }
        };

        const triggerFileInput = () => {
            fileInput.value.click();
        };

        const handleFileUpload = (event) => {
            if (event.target.files.length) {
                processFile(event.target.files[0]);
            }
        };

        const processFile = (file) => {
            // ファイルタイプのチェック
            const allowedTypes = ['video/mp4', 'audio/mp3', 'audio/m4a', 'audio/x-m4a','audio/mpeg'];
            if (!allowedTypes.includes(file.type)) {
                errorMessageModal.value = 'MP4、MP3、またはM4Aファイルを選択してください。';
                return; // ここで処理を中断
            }

            // ファイルサイズのチェック
            if (file.size > 5368709120) { // 5GBを超える場合
                errorMessageModal.value = 'ファイルのサイズが5GBを超えています。';
                return; // ここで処理を中断
            }

            // ファイルタイプがMP4で、サイズも5GB以下の場合
            selectedFile.value = file;
            videoPreview.value = URL.createObjectURL(file);
            isUploadModalOpen.value = true;
        };

        const startUpload = async () => {
            if (!selectedFile.value) {
                errorMessage.value = 'ファイルが選択されていません。';
                return;
            }
            isUploading.value = true;
            uploadError.value = false;
            // アップロードが始まる前に追加情報入力画面を表示
            isAdditionalInfoVisible.value = true;
            let formData = new FormData();
            formData.append('file', selectedFile.value);
            console.log(formData);
            try {
                const accessToken = store.state.accessToken;
                const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;
                const uploadResponse = await axios.post(`${apiEndpoint}/upload-video/`, formData, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'multipart/form-data'
                    },
                });
                // アップロード完了
                //uploadedFileId = uploadResponse.data.video_id; // アップロードされたファイルのIDを保存
                uploadedFileId.value = uploadResponse.data.video_id;
                console.log(uploadResponse.data.video_id);
                console.log(uploadedFileId);
                console.log(uploadedFileId.value);

            } catch (error) {
                console.error("アップロードエラー:", error);
                uploadError = true; // エラーが発生した場合
                if (error.response && error.response.data && error.response.data.error) {
                    const errorText = error.response.data.error;
                    errorMessageModal.value = errorText;
                } else {
                    errorMessageModal.value = '送信中にエラーが発生しました。時間を空けて再度お試しください。';
                }
            }
        };

        // モーダルの開閉
        const openUploadModal = () => {
            isUploadModalOpen.value = true;
        };

        const paginatedVideos = () => {
            let start = (this.currentPage - 1) * this.videosPerPage;
            let end = start + this.videosPerPage;
            return this.videos.slice(start, end);
        };

        const closeUploadModal = () => {
            isUploadModalOpen.value = false;
            isUploading.value = false;
            selectedFile.value = null;
            videoPreview.value = null;
            uploadProgress.value = 0;
            uploadError.value = false;
            isAdditionalInfoVisible.value = false;
            uploadedFileId.value = null;
            youtubeUrl.value = '';
            youtubeTitle.value = '';
            speakerName.value = '';
            speakerTitle.value = '';
            currentVideoId.value = 0;
            resetForm(); // 念のため、フォーム関連のリセットもここで実行
            errorMessageModal.value = ''; //エラーメッセージも削除
        };

        // 追加情報送信
        const submitAdditionalInfo = async () => {
            if (!youtubeUrl.value || !youtubeTitle.value) {
                errorMessage.value = '必要な情報を入力してください。';
                return;
            }
            try {
                const accessToken = store.state.accessToken;
                const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;
                await axios.post(`${apiEndpoint}/add-videodata/`, {
                    youtube_url: youtubeUrl.value,
                    youtube_title: youtubeTitle.value,
                    speaker_name: speakerName.value,
                    speaker_title: speakerTitle.value,
                    uploaded_file_id: currentVideoId.value,
                }, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });
                // 追加情報入力モーダルを閉じる
                isAdditionalInfoModalOpen.value = false;

                resetForm(); // フォームのリセットとバリデーションエラーのクリア
                closeUploadModal();

                router.push({
                    name: 'VideoManagement'
                }).then(() => {
                    fetchVideos();
                });
            } catch (error) {
                errorMessageModal.value = '送信中にエラーが発生しました。時間を空けて再度お試しください。';
            }
        };

        //アップロードの流れで使う関数
        const submitData = async () => {
            // 全ての必須情報が入力されているか確認
            if (!canSubmit.value) return;
            isSubmitting.value = true;
            try {
                const accessToken = store.state.accessToken;
                const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;
                await axios.post(`${apiEndpoint}/add-videodata/`, {
                    youtube_url: youtubeUrl.value,
                    youtube_title: youtubeTitle.value,
                    speaker_name: speakerName.value,
                    speaker_title: speakerTitle.value,
                    uploaded_file_id: Number(uploadedFileId.value)
                }, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    },
                });
                isSubmitting.value = false;
                // アップロード成功モーダルを表示
                showUploadSuccessModal();
                // 追加情報入力画面を閉じる
                isAdditionalInfoVisibl.value = false;
            } catch (error) {
                errorMessageModal.value = '情報の送信に失敗しました。動画管理画面から再度情報の追加をお願いします。';
            }
        };

        // アップロード成功モーダルを閉じる関数
        const handleSuccessModalClose = () => {
            isUploadSuccessModalOpen.value = false;
            isUploadModalOpen.value = false;
            resetForm();
            closeUploadModal();
            router.push({
                name: 'VideoManagement'
            }).then(() => {
                fetchVideos();
            });
        };

        // アップロード成功モーダルを表示する関数
        const showUploadSuccessModal = () => {
            isUploadSuccessModalOpen.value = true;
        };

        const openAdditionalInfoModal = (videoId) => {
            currentVideoId.value = videoId;
            uploadedFileId.value = videoId;
            isAdditionalInfoModalOpen.value = true;
        };

        const closeAdditionalInfoModal = () => {
            isAdditionalInfoModalOpen.value = false;
            resetForm(); // フォームのリセットとバリデーションエラーのクリア
            closeUploadModal();
            router.push({
                name: 'VideoManagement'
            }).then(() => {
                fetchVideos();
            });
        };

        // フォームリセットとエラー状態のクリア関数
        const resetForm = () => {
            youtubeUrl.value = '';
            youtubeTitle.value = '';
            speakerName.value = '';
            speakerTitle.value = '';
            errors.value = {
                youtubeUrl: null,
                youtubeTitle: null,
                speakerName: null
            };
            touched.value = {
                youtubeUrl: false,
                youtubeTitle: false,
                speakerName: false
            };
        };

        const goToArticleSettings = (articleId) => {
            router.push({
                name: 'ArticleSettings',
                params: {
                    id: articleId
                }
            });
        };

        // ガイドラインポップアップウィンドウで同意時の処理
        const contentUpdate = async () => {
            try {
                const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;
                const payload = {
                    user_id: store.state.user_id,
                    guidelineup: process.env.VUE_APP_GUIDELINE_UPDATE,
                    action: 'consent',
                };
                const response = await axios.post(`${apiEndpoint}/user-auth/`, payload, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                if (response.status === 200) {
                    store.commit('setGuidelineConsent', JSON.stringify(response.data.userConsent));
                    console.log('User Guideline consent successfully', response.data);
                }
            } catch (error) {
                errorMessage.value = 'ユーザーの同意に失敗しました。';
                console.error("ユーザーの同意中にエラーが発生しました。", error);
            }
            isGuidelineOpen.value = false;
            document.documentElement.style.removeProperty('overflow-y');
        };

        return {
            videos,
            isUploadModalOpen,
            selectedFile,
            videoPreview,
            isUploading,
            uploadProgress,
            youtubeUrl,
            youtubeTitle,
            speakerName,
            speakerTitle,
            isAdditionalInfoVisible,
            uploadedFileId,
            dragOver,
            uploadError,
            errorMessage,
            errorMessageModal,
            isAdditionalInfoModalOpen,
            isUploadSuccessModalOpen,
            isTranscriptionBusy,
            handleDragOver,
            handleDragLeave,
            handleDrop,
            triggerFileInput,
            handleFileUpload,
            startUpload,
            openUploadModal,
            closeUploadModal,
            openAdditionalInfoModal,
            closeAdditionalInfoModal,
            fileInput, // ファイルインプットの参照をreturnする
            notuploadedvideoImg,
            uploadSuccessIcn,
            fetchVideos, // fetchVideos関数をreturnする
            paginatedVideos,
            goToArticleSettings,
            handleSuccessModalClose,
            submitData,
            submitAdditionalInfo,
            uploadIcn,
            alertIcon,
            clearErrorMessage,
            clearErrorMessageModal,
            validateField,
            errors,
            touched,
            canSubmit,
            showError,
            resetForm,
            showMessage,
            isGuidelineOpen, // ガイドラインポップアップ制御
            canArticleUpdate, // 記事編集ON/OFF
            contentUpdate,
        };
    }
}
</script>
<style scoped>
.group:hover .tooltip {
    opacity: 1;
}

/* ポップアップウィンドウのスタイル */
.popup {
  font-family: Hiragino Kaku Gothic ProN;
  border-color: #d1d5db;
}
.guidelineTitle {
  line-height: 30px;
  inline-size: 185px;
}
/* エクスクラメーションマーク(丸囲み)のスタイル */
.dli-exclamation-circle {
  display: inline-block;
  vertical-align: middle;
  color: white;
  background-color: blue;
  line-height: 1;
  position: relative;
  width: 1.5em;
  height: 1.5em;
  border: 0.1em solid currentColor;
  border-radius: 50%;
  box-sizing: content-box;
}
.dli-exclamation-circle > span {
  width: 0.15em;
  height: 0.6em;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.dli-exclamation-circle > span::before, .dli-exclamation-circle > span::after {
  content: '';
  display: block;
  width: inherit;
  background: currentColor;
}
.dli-exclamation-circle > span::before {
  margin-bottom: 0.1em;
  height: 0.35em;
  border-radius: 0.025em;
}
.dli-exclamation-circle > span::after {
  height: 0.15em;
  border-radius: 50%;
}
.bg-white shadow {
  display:none;
}
</style>
