import axios from 'axios';
import { createStore } from 'vuex';

const initialState = () => {
  return {
    user_id:localStorage.getItem('user_id') || null,
    isAuthenticated: localStorage.getItem('isAuthenticated') === 'true' || false,
    isTokenExpired: localStorage.getItem('isTokenExpired') === 'true' || false,
    accessToken: localStorage.getItem('accessToken') || null,
    tokenExpiry: localStorage.getItem('tokenExpiry') || null,
    channelData: null,
    email:null,
    token:null,
    previewData: localStorage.getItem('previewData') || null,
    guideline:localStorage.getItem('guideline') || null,
  };
};

export default createStore({
  state: initialState(),
  mutations: {
    setUserID(state, user_id) {
      state.user_id = user_id;
      localStorage.setItem('user_id', user_id);
    },
    setChannelData(state, channelData) {
      state.channelData = channelData;
    },
    setemail(state, email){
      state.email = email;
    },
    settoken(state, token){
      state.token = token;
    },
    setPreviewData(state, previewData){
      state.previewData = previewData;
      localStorage.setItem('previewData', previewData);
    },
    setAuthentication(state, status) {
      state.isAuthenticated = status;
      localStorage.setItem('isAuthenticated', status);
    },
    setTokenExpired(state, status) {
      state.isTokenExpired = status;
      localStorage.setItem('isTokenExpired', status);
    },
    setAccessToken(state, token) {
      state.accessToken = token;
      localStorage.setItem('accessToken', token);
    },
    setTokenExpiry(state, expiry) {
      state.tokenExpiry = expiry;
      localStorage.setItem('tokenExpiry', expiry);
    },
    setGuidelineConsent(state, guideline) {
      state.guideline = guideline;
      localStorage.setItem('guideline', guideline);
    },
    clear_register_data(state) {
      state.channelData = null;
      state.email = null;
      state.token = null;
      state.previewData = null;
    },
  },
  actions: {
    async refreshToken({ state, commit }) {
      try {
        // トークンリフレッシュのAPIリクエスト
        console.log(state.user_id);
        const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;
        //'http://127.0.0.1:8000/api/v1/refresh-token/'
        const response = await axios.post(`${apiEndpoint}/refresh-token/`, {
          user_id: state.user_id
    });
        // 新しいトークンと有効期限を保存
        commit('setAccessToken', response.data.access_token);
        commit('setTokenExpiry', response.data.expiry);
        commit('setTokenExpired', response.data.isTokenExpired);

        // 新しいアクセストークンを返す
        return response.data.access_token; 
      } catch (error) {
        commit('setAuthentication', false);
        commit('setTokenExpired', true);
        commit('clear_register_data');
        throw error; // エラーを再スローしてインターセプタでキャッチ可能にする
      }
    },

    login({ commit }, { user_id, accessToken, expiry, guideline }) {
      commit('setAuthentication', true);
      commit('setTokenExpired', false);
      commit('setUserID', user_id);
      commit('setAccessToken', accessToken);
      commit('setTokenExpiry', expiry);
      commit('setGuidelineConsent',guideline);
    },
    async logout({ state, commit }) {
        try {
            // ログアウトAPIへのリクエスト
            const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;
            //'http://127.0.0.1:8000/api/v1/logout/'
            await axios.post(`${apiEndpoint}/logout/`, {
              user_id: state.user_id
            });
            // Vuexの状態を更新
            commit('setUserID',null);
            commit('setAuthentication', false);
            commit('setTokenExpired', false);
            commit('setAccessToken', null);
            commit('setTokenExpiry', null);
            commit('setPreviewData', null);
            commit('setGuidelineConsent',null);

          } catch (error) {
            // エラーハンドリング
            commit('setAuthentication', false);
            commit('setTokenExpired', false);
            commit('setAccessToken', null);
            commit('setTokenExpiry', null);
            commit('setPreviewData', null);
            commit('setGuidelineConsent',null);
          }
    },
    clearregisterdata({ commit }) {
      commit('clear_register_data');
    },
  }
});
