<template>

<!-- プレビュー以外 -->
<div v-if="!isPreviewPage">
    <div class="flex flex-col min-h-screen bg-gray-100">
        <header class="bg-white shadow">

            <nav class="mx-auto px-4 py-3 flex justify-between items-center">

                <!-- メニューボタン（SPでログイン時のみ表示） -->
                <div v-if="!isLoginRelatedPage && !isGaidelinePage" ref="navLeftDiv" class="md:hidden">
                    <button @click="toggleMenu" class="text-emerald-600 hover:text-emerald-700">
                        <!-- メニューアイコン SVG -->
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7" />
                        </svg>
                    </button>
                </div>

                <!-- ロゴ（常に表示、SPでは中央に配置） -->
                <div class="flex-grow md:flex-grow-0 md:flex md:justify-start">
                    <router-link to="/" class="text-emerald-600 hover:text-emerald-700">
                        <img :src="logo" alt="Logo" class="h-8 mx-auto md:mx-0" style="width: 108px;" />
                    </router-link>
                </div>

                <div ref="navRightDiv" class="flex justify-between items-center gap-4">
                    <!-- ログアウトボタン（ログイン時のみ表示、右端に配置） -->
                    <button v-if="!isLoginRelatedPage && !isGaidelinePage" @click="handleAuthAction" type="button"
                        class="flex items-center gap-1 cursor-pointer text-sm">
                        <img :src="loginIcn" class="w-3.5 h-3.5"/>
                        <span class="hidden md:inline whitespace-nowrap text-gray-500 hover:text-gray-600">ログアウト</span>
                    </button>
                    <!-- ヘルプ -->
                    <a href="https://note.com/livedoor_echoes/m/m1d8d4ed94dc8" target="_blank"
                        class="flex items-center gap-1 cursor-pointer text-sm">
                        <img :src="helpIcn" class="w-3.5 h-3.5">
                        <span class="hidden md:inline whitespace-nowrap text-gray-500 hover:text-gray-600">ヘルプ</span>
                    </a>
                </div>
            </nav>
        </header>

        <!-- ガイドライン以外 -->
        <div v-if="!isGaidelinePage">
            <div class="flex flex-col md:flex-row flex-1 min-h-screen scrollbar-x-hidden" style="overflow: auto; scrollbar-width: none; /* Firefox用 */ -ms-overflow-style: none;  /* Internet Explorer 10+用 */">
                <!-- メニュー -->
                <nav v-if="!isLoginRelatedPage" :class="{'block w-full': isMenuOpen, 'hidden': !isMenuOpen}" class="md:block px-4 py-6">
                    <ul class="bg-transparent">
                        <li class="group font-normal">
                            <router-link to="/video-management" class="flex items-center w-full md:w-52 h-11 px-4 rounded-lg text-gray-700 hover:bg-gray-200 group-active:bg-gray-200 gap-4" @click="closeMenu" active-class="bg-gray-200 font-bold">
                                <i class="fa-light fa-video h-4 w-4"></i>
                                <span>動画管理</span>
                            </router-link>
                        </li>
                        <li class="group font-normal">
                            <router-link to="/article-management" class="flex items-center w-full md:w-52 h-11 px-4 rounded-lg text-gray-700 hover:bg-gray-200 group-active:bg-gray-200 gap-4" @click="closeMenu" active-class="bg-gray-200 font-bold">
                                <i class="fa-light fa-newspaper h-4 w-4"></i>
                                <span>記事管理</span>
                            </router-link>
                        </li>
                        <li class="group font-normal">
                            <router-link to="/channel-management" class="flex items-center w-full md:w-52 h-11 px-4 rounded-lg text-gray-700 hover:bg-gray-200 group-active:bg-gray-200 gap-4" @click="closeMenu" active-class="bg-gray-200 font-bold">
                                <i class="fa-light fa-address-card h-4 w-4"></i>
                                <span>チャンネル管理</span>
                            </router-link>
                        </li>
                        <li class="group font-normal">
                            <router-link to="/information" class="flex items-center w-full md:w-52 h-11 px-4 rounded-lg text-gray-700 hover:bg-gray-200 group-active:bg-gray-200 gap-4" @click="closeMenu" active-class="bg-gray-200 font-bold">
                                <img :src="infoIcn" class="h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <span class="w-36 h-6 text-base" style="line-height: 24px;">お知らせ</span>
                            </router-link>
                        </li>
                    </ul>
                </nav>
                <div class="w-full mb-20">
                    <router-view />
                </div>
            </div>
        </div>

        <!-- ガイドライン用 -->
        <div v-else>
            <div class="flex flex-col min-h-screen bg-gray-100">
                <!-- メインコンテンツ -->
                <div class="flex flex-col md:flex-row flex-1 ">
                    <div class="w-full mb-20">
                        <router-view />
                    </div>
                </div>
            </div>
        </div>

        <!-- 共通 -->
        <footer class="p-4 flex flex-wrap justify-between items-center text-xs bg-transparent fixed bottom-0 w-full bg-white">
            <div class="flex flex-wrap gap-4 justify-center md:justify-start">
                <a href="https://livedoor.co.jp/" target="_blank" class="text-gray-500 hover:text-gray-600">運営会社</a>
                <a href="https://www.livedoor.com/rules/" target="_blank" class="text-gray-500 hover:text-gray-600">利用規約</a>
                <a href="https://www.livedoor.com/privacy/" target="_blank" class="text-gray-500 hover:text-gray-600">プライバシーポリシー</a>
                <a href="/guideline" target="_blank" class="text-gray-500 hover:text-gray-600">ガイドライン</a>
            </div>
            <div>© livedoor</div>
        </footer>
    </div>
</div>

<!-- プレビュー画面用 -->
<div v-else>
    <div class="flex flex-col min-h-screen bg-gray-100">
        <!-- メインコンテンツ -->
        <div class="flex flex-col md:flex-row flex-1 ">
            <div class="w-full mb-20">
                <router-view />
            </div>
        </div>
    </div>
</div>

</template>



<script>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';
import { useRouter,useRoute } from 'vue-router';
import logo from '@/assets/echoes_icon.svg';
import loginIcn from '@/assets/loginIcn.svg';
import helpIcn from '@/assets/helpIcn.svg';
import infoIcn from '@/assets/informationIcn.svg';
import { BASE_URL_DEV, BASE_URL_LOCAL, BREAK_POINT_MD } from '@/plugins/constants';

export default {
    setup() {
        const isMenuOpen = ref(false);
        const toggleMenu = () => {
            isMenuOpen.value = !isMenuOpen.value;
        };
        function closeMenu() {
            isMenuOpen.value = false;
        }

        const store = useStore();
        const router = useRouter();
        const route = useRoute();
        const isAuthenticated = computed(() => store.state.isAuthenticated);
        const isTokenExpired = computed(() => store.state.isTokenExpired);

        const handleAuthAction = () => {
            if (!isAuthenticated.value || isTokenExpired.value) {
                router.push('/login');
            } else {
                store.dispatch('clearregisterdata');
                store.dispatch('logout');
                router.push({
                    name: 'Login'
                });
            }
        };

        // 現在のページがログイン関連ページかどうかを判断するcomputedプロパティ
        const isLoginRelatedPage = computed(() => {
            return ['Login', 'RegistrationComplete', 'AccountCreation' ,'AccountCreated'].includes(route.name);
        });

        // 現在のページがプレビューページかどうかを判断するcomputedプロパティ
        const isPreviewPage = computed(() => {
            return ['ArticlePreview'].includes(route.name);
        });

        // 現在のページがガイドラインページかどうかを判断するcomputedプロパティ
        const isGaidelinePage = computed(() => {
            return ['UserGuideline'].includes(route.name);
        });

        onMounted(() => {
            const baseUrl = window.location.origin;
            if(baseUrl === BASE_URL_DEV){
                document.title = "ECHOES（dev）";
            }else if(baseUrl == BASE_URL_LOCAL){
                document.title = "ECHOES（local）";
            }
        })

        const navRightDiv = ref(null);
        const navLeftDiv = ref(null);
        const navRightWidth = ref(0);

        // ナビゲーション内の左右の要素のwidthを整え、ロゴを中央に配置する（ブレイクポイント（768px））
        const updateWidths = () => {
            if(navLeftDiv.value == null){
                return;
            }
            if (window.innerWidth < BREAK_POINT_MD) {
                navRightWidth.value = navRightDiv.value.offsetWidth;
                navLeftDiv.value.style.width = `${navRightWidth.value}px`;
            } else {
                navLeftDiv.value.style.width = 'auto';
            }
        };

        onMounted(() => {
            updateWidths();
            window.addEventListener('resize', updateWidths);
        });

        onBeforeUnmount(() => {
            window.removeEventListener('resize', updateWidths);
        });

        return {
            isMenuOpen,
            closeMenu,
            toggleMenu,
            handleAuthAction,
            logo,
            isLoginRelatedPage,
            isPreviewPage,
            isGaidelinePage,
            navRightDiv,
            navLeftDiv,
            loginIcn,
            helpIcn,
            infoIcn,
        };
    }
};
</script>

<style>
body {
    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "Hiragino Kaku Gothic ProN", "Hiragino Sans", Arial, Meiryo, sans-serif;
    color: #374151;
}
.scrollbar-x-hidden{
    display: none; /* Chrome, Safari用 */
}
</style>
