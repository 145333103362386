<template>

<Information />

<div class="bg-white p-8 w-11/12 mx-auto mt-6">
    <h2 class="text-2xl font-bold text-gray-700 mb-6">チャンネル管理</h2>
    <p class="mb-4 text-gray-600">チャンネル情報を入力してください。チャンネルURLの入力がお済みでない場合、記事投稿ができません。</p>

    <div class="grid grid-cols-1 gap-y-6">
        <div>
            <div class="flex items-center space-x-4 mb-4">

                <img :src="channel.channel_icon || defaultIcon" alt="チャンネルアイコン" class="h-20 w-20 object-cover rounded-full">
                <div class="break-all">
                    <p class="font-bold text-gray-600">チャンネルURL</p>
                    <p>{{ channel.channel_url }}</p>
                </div>
            </div>

        </div>
        <div class="mb-4">
            <label class="block mb-2" for="accountName">アカウント名</label>
            <input id="accountName" v-model="channel.account_name" @input="validateForm" class="p-2 border border-gray-300 rounded w-full" />
            <p class="text-right text-gray-600">{{ channel.account_name.length }}/50</p>
            <p v-if="errors.account_name" class="text-red-500">{{ errors.account_name }}</p>
        </div>

        <div class="mb-4">
            <label class="block mb-2" for="channelDescription">チャンネル説明</label>
            <textarea id="channelDescription" v-model="channel.channel_description" @input="validateForm" class="p-2 border border-gray-300 rounded w-full"></textarea>
            <p class="text-right text-gray-600">{{ channel.channel_description.length }}/160</p>
            <p v-if="errors.channel_description" class="text-red-500">{{ errors.channel_description }}</p>
        </div>

    </div>

    <div class="flex justify-end space-x-4 mt-8">
        <router-link to="/channel-management" class="btn-secondary">キャンセル</router-link>
        <button :disabled="!isEditable" @click="saveChannel" class="btn-primary disabled:btn-disabled">保存する</button>
    </div>
    <div v-if="flashMessage" class="fixed right-0 bottom-16 mb-4 mr-4 bg-green-500 text-white py-2 px-4 rounded-lg shadow-lg">
        {{ flashMessage }}
    </div>

</div>
</template>

<script>
import axios from 'axios';
import {
    useStore
} from 'vuex';
import {
    ref,
    reactive,
    watch
} from 'vue';
import defaultIcon from '@/assets/defaultIcn.svg';
import Information from './InformationTable.vue';

export default {
    name: 'ChannelManagement',
    components: {
        Information
    },
    setup() {
        const store = useStore(); // Vuex ストアを使用
        const channel = reactive({
            channel_url: '',
            account_name: '',
            channel_description: '',
            channel_icon: '',
            id: ''
        });
        const originalChannel = ref({});
        const errors = reactive({});
        const isEditable = ref(false);
        const flashMessage = ref('');

        const fetchChannel = async () => {
            const accessToken = store.state.accessToken; // ストアからアクセストークンを取得
            const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;
            try {
                const response = await axios.get(`${apiEndpoint}/channels/`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });
                console.log(response.data);
                Object.assign(channel, response.data[0]);
                console.log(channel);
                originalChannel.value = JSON.parse(JSON.stringify(channel));
            } catch (error) {
                console.error("チャンネル情報の取得に失敗しました。", error);
            }
        };

        const validateForm = () => {
            // アカウント名のバリデーション
            errors.account_name = '';
            if (!channel.account_name) {
                errors.account_name = 'アカウント名は必須です。';
            } else if (channel.account_name.length > 50) {
                errors.account_name = 'アカウント名は50文字以内で入力してください。';
            }

            // チャンネル説明のバリデーション
            errors.channel_description = '';
            if (channel.channel_description.length > 160) {
                errors.channel_description = 'チャンネル説明は160文字以内で入力してください。';
            }
            // 変更があるかどうかをチェックし、エラーがない場合のみisEditableをtrueに設定
            const hasChanges = JSON.stringify(channel) !== JSON.stringify(originalChannel.value);
            isEditable.value = hasChanges && Object.keys(errors).every(key => !errors[key]);
        };

        const saveChannel = async () => {
            const formData = new FormData();
            formData.append('account_name', channel.account_name);
            formData.append('channel_description', channel.channel_description);
            if (typeof channel.channel_icon !== 'string') {
                formData.append('channel_icon', event.target.files[0]);
            }
            const accessToken = store.state.accessToken;
            const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;
            try {
                await axios.put(`${apiEndpoint}/channels/${String(channel.id)}/`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${accessToken}`
                    },
                });
                flashMessage.value = "保存に成功しました。";
                setTimeout(() => flashMessage.value = '', 3000);
                fetchChannel(); // 更新後の情報を再取得
            } catch (error) {
                console.error("保存に失敗しました。", error);
                flashMessage.value = "保存に失敗しました。";
                setTimeout(() => flashMessage.value = '', 3000);
            }
        };

        watch(() => channel, validateForm, {
            deep: true
        });

        fetchChannel();

        return {
            channel,
            errors,
            isEditable,
            saveChannel,
            flashMessage,
            defaultIcon
        };
    },
};
</script>
