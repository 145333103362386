<template>
    <div class="flex flex-col py-8 px-6 mx-auto gap-4">
        <div class="flex flex-col bg-white px-8 py-12 gap-6">
            <div class="flex pb-2 gap-2">
                <h2 class="text-2xl font-bold text-gray-700 text-left flex items-center">運営からのお知らせ</h2>
            </div>
            <div class="flex w-full border-b border-solid border-gray-300 gap-1">
                <div class="flex w-full py-4 gap-2">
                    <span class="w-full font-semibold text-base text-gray-700 text-left flex items-center">{{ title }}</span>
                </div>
            </div>
            <span class="py-2 font-light text-xs text-gray-700">{{ start_datetime }}</span>
            <div class="flex flex-col gap-7">
                <div class="flex flex-col gap-1">
                    <span class="text-sm font-normal hyper-link" v-html="formatBody(body)"></span>
                </div>
            </div>
        </div>
    </div>
</template>


<script setup>
    import { ref, onMounted }  from 'vue';
    import axios from 'axios';
    import { useRoute, useRouter } from 'vue-router';
    import store from '../store';

    const route = useRoute();
    const router = useRouter();
    const title = ref("");
    const body = ref("");
    const start_datetime = ref("");

    onMounted(() => {
        fetchInformationDetails();
    });

    const fetchInformationDetails = () => {
        const accessToken = store.state.accessToken;
        const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;
        let postData = {
            action: 'details',
            information_id: route.params.id
        };
        axios.post(`${apiEndpoint}/informations/`, postData,{
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        }).then(response => {
            const data = response.data.informationDetails;
            title.value = data.title;
            body.value = data.body;
            start_datetime.value = data.start_datetime;
        })
        .catch(error => {
            if (error.response && error.response.status === 404) {
                router.push({ name: 'NotFound' });
            } else {
                console.log(error);
            }
        });
    }

    const formatBody = (content) => {
        var body = content.replace(/\n/g, '<br>');
        body = body.replace(/<\/a><br><br>/g, '</a>');
        return body
    }

</script>

<style>
.hyper-link a{
    color: #2563EB;
    overflow-wrap: anywhere;
}
</style>